import { Icon } from '@EPIC'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import ListItem from '../ListItem/ListItem.js'
import TextInput from '../TextInput/TextInput.js'
import styles from './style.styl'

const removeFakePath = (v) => v.replace('C:\\fakepath\\', '')

const imageTypes = ['image/bmp', 'image/gif', 'image/jpeg', 'image/tiff', 'image/png']

/*
  When using `multiple` the native file input provides a READ_ONLY list of files
  see: https://stackoverflow.com/a/3162319
  This means we cannot delete an individual file from it, only clear the entire input
  We want to do this, so we need our own list of files that is reported to onFileChange

  onChange receives the entire synthetic event, so changing it to receive non-deleted files would be a breaking change
  I've addded a note as such, and onChange should be considered DEPRECATED and removed in the next breaking release
*/

// TODO: vNext (BREAKING) consider removing onChange since it cannot safely report the deleted files
const FileInput = forwardRef(
  (
    {
      hasErrors,
      accept,
      className,
      onChange,
      onFileChange,
      onRemove,
      placeholder,
      multiple,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState('')
    const [files, setFiles] = useState([])
    const [inputKey, setInputKey] = useState(Date.now())
    const input = useRef()

    const active = !!value
    let classList = classnames(styles.fileInput, className)
    if (props.disabled) classList = classnames(classList, styles.disabled)
    if (active) classList = classnames(classList, styles.active)
    if (hasErrors) classList = classnames(classList, styles.hasErrors)

    const clearInput = useCallback(() => {
      // Update the key to replace the input value and clear the file
      setInputKey(Date.now())

      // Clear the focus
      if (input.current) {
        input.current.focus()
        setTimeout(() => input.current.blur(), 100)
      }
    }, [])

    const onInputChange = useCallback(
      (e) => {
        setFiles((files) => {
          const newFiles = Array.from(e.target.files).filter(
            (file) => !files.find((f) => f.name === file.name)
          )
          return files.concat(newFiles)
        })
        onChange && onChange(e)
        if (!multiple) {
          setValue(e.target && e.target.value)
        } else {
          clearInput()
        }
      },
      [multiple]
    )

    const onInputRemove = useCallback(() => {
      clearInput()

      setValue('')
      setFiles([])
      onRemove && onRemove()
    }, [onRemove])

    function onDeleteFile(name) {
      setFiles(files.filter((file) => file.name !== name))
    }

    // We need this to trigger for all changes to files, not just add
    useEffect(() => {
      onFileChange && onFileChange(files)
    }, [files])

    // allows apps to launch the file window programmatically
    useImperativeHandle(ref, () => ({
      launchFileWindow: () => {
        input?.current?.click()
      },
    }))

    const label = value ? removeFakePath(value) : placeholder
    return (
      <>
        {multiple && files && (
          <div>
            {files.map((file) => {
              const thumbnail = imageTypes.includes(file.type) ? (
                <img height='auto' src={URL.createObjectURL(file)} />
              ) : (
                <Icon type='Hide' />
              )

              return (
                <ListItem
                  key={file.name}
                  thumbnail={thumbnail}
                  subtitle={new Date(Date.now()).toDateString()}
                  name={file.name}
                  iconType='Delete'
                  onAction={() => onDeleteFile(file.name)}
                />
              )
            })}
          </div>
        )}
        <div className={classList}>
          <label className={styles.fileInputLabel}>
            <span>{label}</span>
          </label>
          <TextInput
            ref={input}
            key={inputKey}
            placeholder=''
            accept={accept || '*'}
            type='file'
            hasErrors={hasErrors}
            floating={false}
            onChange={onInputChange}
            multiple={multiple ? 'multiple' : ''}
            {...props}
          />
          <button
            type='button'
            className={styles.fileInputBtn}
            onClick={active ? onInputRemove : undefined}
          >
            <Icon type={active ? 'Close' : 'Plus'} size='m' />
          </button>
        </div>
      </>
    )
  }
)

FileInput.propTypes = {
  /** name for input */
  name: PropTypes.string,
  /** MIME types accept attribute for file input element. defaults to `*` */
  accept: PropTypes.string,
  /** class for textInput section of fileinput  */
  className: PropTypes.string,
  /** onRemove handler for input, will remove file from input */
  onRemove: PropTypes.func,
  /** onChange handler for input, returns whole event for full control of file data. When `multiple` is set this returns all files, even deleted ones */
  onChange: PropTypes.func,
  /** onChange handler for files, is called with the current list of files. Use instead of `onChange` for `multiple` */
  onFileChange: PropTypes.func,
  /** Disable the control */
  disabled: PropTypes.bool,
  /** Help text */
  helperText: PropTypes.string,
  /** Sets whether input is in error state */
  hasErrors: PropTypes.bool,
  /** Error message to be displayed to user. If errorMessage is supplied, helperText will not be displayed */
  errorMessage: PropTypes.string,
}

FileInput.defaultProps = {
  className: '',
  placeholder: 'Select a file',
  disabled: false,
}

export default FileInput
