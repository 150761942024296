/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import { navigate } from 'raviger'
import { Button, IconButton } from '@nike/epic-react-ui'
import { mediumText, buttonGroupStyle, themedButton } from '../commonStyles'
import Spacer from '../components/Spacer'
interface RegistrationCompleteProps {
  successful: boolean
  resetRegistration: () => void
}

function RegistrationComplete (props: RegistrationCompleteProps) {
  const { successful, resetRegistration } =props

  return (
    <div style={{ margin: 25 }}>
      <h2 className='epic-font-brand'>
        {successful ? 'Experience Registration Successful' : 'Experience Registration Failed'}
      </h2>
      <div style={mediumText}>
        {successful ? 'Consumer redirect experience successfully registered' : 'Consumer redirect experience could not be registered'}
      </div>
      <Spacer height={25}/>
      <div style={buttonGroupStyle}>
        <IconButton type='arrow-backward' positionFirst onClick={() => navigate('/manage-experiences')} theme={themedButton}>
          Experiences List
        </IconButton>
        <Button onClick={resetRegistration} theme={themedButton} style={{ marginLeft: 10 }}>
          Register Experience
        </Button>
      </div>
    </div>
  )
}

export default RegistrationComplete
