import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles.styl'

const Badge = ({ count, className }) => {
  return <span badge-data={count} className={`${styles.badge} ${className}`.trim()} />
}

Badge.propTypes = {
  /** count for badge */
  count: PropTypes.number,
  /** component specific styling for badge */
  className: PropTypes.string,
}

Badge.defaultProps = {
  count: 0,
  className: '',
}

export default Badge
