import React, { useEffect } from "react"
import { connect } from "react-redux"

import { useRoutes, Link } from "raviger"
import {
  initializeUserAuth,
  hasAuthCheckFinished,
  selectLoginError,
  loginUser,
  logoutUser,
} from "@nike/oauth-client"
import { Button, NavBar } from "@nike/epic-react-ui"

import config from "../config"
import routes from "./routes"
import NotFoundPage from "./NotFoundPage"
import { LoginRequired } from "../auth"
import Spinner from "../components/Spinner"
import Error from "../components/Error"

const navBarLinks = [
  { text: "Manage Experiences", path: "/manage-experiences" },
  { text: "Register Experience", path: "/register" },
]
interface AppInterface {
  initializeUserAuth: () => void;
  loginError: string;
  loginFinished: boolean;
  loginUser: () => void;
  logoutUser: () => void;
}
const App = ({
  initializeUserAuth,
  loginError,
  loginFinished,
  loginUser,
  logoutUser,
}: AppInterface) => {
  useEffect(() => {
    initializeUserAuth()
  }, [])

  const routeResult = useRoutes(routes) || <NotFoundPage />

  if (loginError) {
    return (
      <div>
        <Error error={loginError} />
        <Button onClick={loginUser}>Login</Button>
      </div>
    )
  }

  if (!loginFinished) {
    return <Spinner large/>
  }

  const logoutButton = (
    <Button onClick={logoutUser}>
      Logout
    </Button>
  )

  return (
    <div>
      <div>
        <NavBar
          name={config.appFullName}
          logo="/logo.png"
          rootPath="/manage-experiences"
          routes={navBarLinks}
          RouterLink={Link}
          aside={logoutButton}
        ></NavBar>
        <LoginRequired title={'Home'}>
          <div style={{ margin: "1rem" }}>{routeResult}</div>
        </LoginRequired>
      </div>
    </div>
  )
}

export default connect(
  (state) => ({
    loginFinished: hasAuthCheckFinished(state),
    loginError: selectLoginError(state),
  }),
  { initializeUserAuth, loginUser, logoutUser }
)(App)
