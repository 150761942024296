import PropTypes from 'prop-types'
import React from 'react'
import ReactScrollspy from 'react-scrollspy'

import styles from './styles.styl'

const Scrollspy = ({
  title,
  children,
  className,
  uppercaseTitle,
  uppercaseItems,
  items,
  rootEl,
  ...props
}) => (
  <div className={className || ''}>
    {title && (
      <h3 className={`${styles.navTitle} ${uppercaseTitle && styles.uppercase}`}>{title}</h3>
    )}
    <ReactScrollspy
      items={items.map((l) => l.hash)}
      className={styles.navLinks}
      currentClassName={styles.activeNavItem}
      rootEl={rootEl}
      {...props}
    >
      {items.map((l) => {
        return (
          <li key={`nav-${l.text}`} className={styles.listItem}>
            <a href={`#${l.hash}`} className={`${uppercaseItems && styles.uppercase}`}>
              {l.text}
              <span className={styles.activeLinkBar} />
            </a>
          </li>
        )
      })}
    </ReactScrollspy>
    {children}
  </div>
)

Scrollspy.propTypes = {
  /** Header text for the vertical navigation items */
  title: PropTypes.string,
  /** Array of item objects */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      hash: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** This value is passed to querySelector to determine the scrollable container */
  rootEl: PropTypes.string,
  /** Class name to be used for outermost div element */
  className: PropTypes.string,
  /** Uppercase the title */
  uppercaseTitle: PropTypes.bool,
  /** Uppercase items */
  uppercaseItems: PropTypes.bool,
  /** Children */
  children: PropTypes.PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

Scrollspy.defaultProps = {
  uppercaseTitle: false,
  uppercaseItems: false,
}

export default Scrollspy
