import { Label } from '@EPIC'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import styles from './ToggleSwitch.styl'

let id = 0

class ToggleOption extends Component {
  constructor(...props) {
    super(...props)
    this.id = id++
  }

  render() {
    let { name, onChange, option, value, clearable } = this.props

    return (
      <div className={styles.toggleItem}>
        <input
          type='radio'
          name={name}
          id={`er-toggle-switch-${this.id}`}
          checked={option.value === value}
          readOnly
        />
        <label
          className='epic-font-base-md'
          onClick={() =>
            onChange(option.value === value ? (clearable ? null : option.value) : option.value)
          }
          htmlFor={`er-toggle-switch-${this.id}`}
        >
          {option.label}
        </label>
      </div>
    )
  }
}

const ToggleSwitch = ({
  label,
  required,
  hasErrors,
  errorMessage,
  options,
  className = '',
  ...props
}) => (
  <Label label={label} required={!!required} hasErrors={hasErrors} errorMessage={errorMessage}>
    <span className={`${styles.toggle} ${className}`}>
      {options.map((option) => (
        <ToggleOption key={option.value} option={option} {...props} />
      ))}
    </span>
  </Label>
)

ToggleSwitch.propTypes = {
  /** label for the ToggleSwitch */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  /** required flag for the ToggleSwitch */
  required: PropTypes.bool,
  /** Sets whether input is in error state */
  hasErrors: PropTypes.bool,
  /** Error message to be displayed to user, appears in top right of input */
  errorMessage: PropTypes.string,
  /** onChange handler for the ToggleSwitch */
  onChange: PropTypes.func,
  /** Options for toggle, takes label and value */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  /** className passed to FormSection */
  className: PropTypes.string,
  /** Specifies whether toggle can exist in null state */
  clearable: PropTypes.bool,
}

ToggleSwitch.defaultProps = {
  clearable: false,
  className: '',
  options: [],
}

export default ToggleSwitch
