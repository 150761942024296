import qs from 'qs'

const globalHeaders = {}

export function setGlobalHeader (name, value) {
  globalHeaders[name] = value
}

function processError (r, contentType) {
  if (contentType === 'application/json') {
    return r.json().then(res => {
      const error = new Error(res ? res.message : r.status)
      error.status = r.status
      throw error
    })
  }
  console.log('ERROR', r.body, contentType, r.status, r.type)
  throw new Error()
}

function processResponse (r) {
  const contentType = r.headers.get('content-type')
  if (!r.ok) {
    return processError(r, contentType)
  }
  if (r.body instanceof ReadableStream) {
    const reader = r.body.getReader()
    const stream = new ReadableStream({
      start(controller) {
        return pump()
  
        function pump() {
          return reader.read().then(({ done, value }) => {
            if (done) {
              controller.close()
              return
            }
  
            controller.enqueue(value)
            return pump()
          })
        }
      }
    })
 
    const response = new Response(stream)
    return response.json()
  } else if (contentType === 'application/json') {
    return r.json()
  } else if (contentType === 'application/octet-stream') {
    return r.blob()
  } 
  return r
}

export default {
  post (url, payload, headers) {
    return fetch(url, {
      method: 'POST',
      headers: Object.assign(
        { 'Content-Type': 'application/json' },
        globalHeaders,
        headers
      ),
      body: JSON.stringify(payload)
    }).then(processResponse)
  },
  get (url, query) {
    if (query) {
      const queryString = qs.stringify(query)
      url = url + '?' + queryString
    }

    return fetch(url, {
      headers: globalHeaders
    }).then(processResponse)
  },
  delete (url) {
    return fetch(url, {
      method: 'DELETE',
      headers: globalHeaders
    }).then(processResponse)
  },
  put (url, payload, headers) {
    return fetch(url, {
      method: 'PUT',
      headers: Object.assign(
        { 'Content-Type': 'application/json' },
        globalHeaders,
        headers
      ),
      body: JSON.stringify(payload)
    }).then(processResponse)
  }
}
