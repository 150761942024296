import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './StepFormProgressBars.styl'

const StepFormProgressBars = ({ steps, activeStepIndex }) => (
  <div className={styles.stepFormProgress}>
    {steps.map((step, index) => {
      const isActiveStep = activeStepIndex >= index

      return (
        <div key={step.title} className={styles.item}>
          <div className={styles.barContainer}>
            <div
              className={classnames(styles.bar, {
                [styles.barHighlighted]: isActiveStep,
                [styles.barDefault]: !isActiveStep,
              })}
            />
          </div>
          <span
            className={classnames({
              [styles.itemTitleHighlighted]: isActiveStep,
              [styles.itemTitleDefault]: !isActiveStep,
            })}
          >
            {step.title}
          </span>
        </div>
      )
    })}
  </div>
)

StepFormProgressBars.propTypes = {
  /** Ordered array of steps in form */
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** Active form step identifier */
  activeStepIndex: PropTypes.number,
}

export default StepFormProgressBars
