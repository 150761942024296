import { selectEmail } from '@nike/oauth-client'
import { connect } from 'react-redux'
import RegisterPage from './RegisterPage'

  
  const mapStateToProps = state => ({
    email: selectEmail(state),
  })
  
  export default connect(mapStateToProps)(RegisterPage) 
