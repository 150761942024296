import { Label, RadioButton } from '@EPIC'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './RadioGroup.styl'

function RadioGroup({ list, name, value, onChange, className, ...rest }) {
  return (
    <Label {...rest} className={styles.label}>
      <span
        className={className ? `${styles.radioGroup} ${className}` : styles.radioGroup}
        data-testid='RadioButtonsWrapper'
      >
        {list.map((item) => {
          return (
            <RadioButton
              name={name}
              value={item.value}
              label={item.label}
              aria-label={item.label}
              key={item.label}
              checked={value === item.value}
              onChange={() => onChange(item.value)}
            />
          )
        })}
      </span>
    </Label>
  )
}

RadioGroup.propTypes = {
  /** Shape of the data needed to be passed into the component */
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  /** name attribute to be passed to radioButton elements */
  name: PropTypes.string,
  /** value of RadioGroup */
  value: PropTypes.any,
  /** label for the RadioGroup */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  /** required flag for the RadioGroup */
  required: PropTypes.bool,
  /** onChange handler for the RadioGroup */
  onChange: PropTypes.func.isRequired,
  /** className applied to RadioGroup */
  className: PropTypes.string,
  /** Any html attributes to apply to component */
  attributes: PropTypes.object,
}

RadioGroup.defaultProps = {
  list: [],
}

export default RadioGroup
