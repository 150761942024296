import config from "../config"
import { Rule } from "../register/Register"
import http from "../util/http"

const API_URL = config.apiUrl

export interface CreateExperiencePayload {
  experience: string
  redirectUrl: string
}

export interface RegisterToExperiencePayload {
  qrCode: string
  experienceSlug: string
  ttl: number
}

export interface RegisterToExperienceResponse {
  redirectUrl: string
}

export async function listRules(): Promise<any> {
  try {
    const response = await http.get(`${API_URL}/rules`)
    return response
  } catch (err: any) {
    console.log("GET rules", err.toString())
    return err
  }
}

export async function getRule(name: string): Promise<any> {
  try {
    const response = await http.get(`${API_URL}/rule/${name}`)
    return response
  } catch (err: any) {
    console.log("GET rule", err.toString())
    return err
  }
}

export async function deleteRule(name: string): Promise<any> {
  try {
    const response = await http.delete(`${API_URL}/rule/${name}`)
    return response
  } catch (err: any) {
    console.log("DELETE rule", err.toString())
    return err
  }
}

export async function updateRule(name: string, rule: Rule): Promise<any> {
  try {
    const response = await http.put(`${API_URL}/rule/${name}`, rule)
    return response
  } catch (err: any) {
    console.log("UPDATE rule", err.toString())
    return err
  }
}

export async function createRule(rule: Rule): Promise<any> {
  try {
    const response = await http.post(`${API_URL}/rules`, JSON.stringify(rule))
    return response
  } catch (err: any) {
    console.log("CREATE rule",`${API_URL}/rules`, err.toString())
    return err
  }
}
