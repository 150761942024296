// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".spinner___2W0Q0{border-radius:50%;border-top:2px solid #03ade0;border-right:2px solid transparent;animation:spinner___2W0Q0 .6s linear infinite;-webkit-animation:spinner___2W0Q0 .6s linear infinite;height:1rem;width:1rem}.spinnerLG___AMf4z{width:10rem;height:10rem}.spinnerMD___2UYZy{width:5rem;height:5rem}.spinner___2W0Q0:not(:required):before{border-radius:50%;animation:spinner___2W0Q0 .6s linear infinite;-webkit-animation:spinner___2W0Q0 .6s linear infinite}@-moz-keyframes spinner___2W0Q0{to{transform:rotate(360deg)}}@-webkit-keyframes spinner___2W0Q0{to{transform:rotate(360deg)}}@-o-keyframes spinner___2W0Q0{to{transform:rotate(360deg)}}@keyframes spinner___2W0Q0{to{transform:rotate(360deg)}}@-moz-keyframes spinner___2W0Q0{to{-webkit-transform:rotate(360deg)}}@-webkit-keyframes spinner___2W0Q0{to{-webkit-transform:rotate(360deg)}}@-o-keyframes spinner___2W0Q0{to{-webkit-transform:rotate(360deg)}}@keyframes spinner___2W0Q0{to{-webkit-transform:rotate(360deg)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": "spinner___2W0Q0",
	"spinnerLG": "spinnerLG___AMf4z",
	"spinnerMD": "spinnerMD___2UYZy"
};
export default ___CSS_LOADER_EXPORT___;
