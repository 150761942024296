import React from "react"

const Spacer = (props: { height: number }) => {
  const { height } = props
  return <div style={{ height }} />
}

Spacer.propTypes = {}

export default Spacer
