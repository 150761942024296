import { Modal, Button } from "@nike/epic-react-ui"

interface Modal {
  title: string;
  show: boolean;
  setModal: (value: boolean) => void;
  children: unknown;
  button: unknown;
  action: () => void;
}

const RuleModal = ({
  title,
  show,
  setModal,
  children,
  button,
  action,
}: Modal) => (
  <div>
    <Modal onClose={() => setModal(false)} show={show} swoosh title={title}>
      {children}
      {button && (
        <Button theme={{}} onClick={action}>
          {button}
        </Button>
      )}
    </Modal>
  </div>
)

export default RuleModal
