import PropTypes from 'prop-types'
/* eslint import/namespace: ['error', { allowComputed: true }] */
import React from 'react'

// eslint-disable-next-line import/extensions, import/no-namespace
import * as CXIcons from './cx-icon-components'

const sizes = {
  s: 'Small',
  m: 'Medium',
  l: 'Large',
}

function CXIcon({ type, size, ...props }) {
  if (!type) return null
  const compName = `${type}${sizes[size]}`
  const IconComponent = CXIcons[compName]
  if (!IconComponent) return null
  return <IconComponent type={type} size={size} {...props} />
}

const CXTypes = [
  'Alert',
  'Database',
  'Desktop',
  'History',
  'Lock',
  'NewTab',
  'OpenLink',
  'OverflowMenu',
  'Preview',
  'SlackLogo',
  'SortAscending',
  'SortDescending',
  'Support',
]

CXIcon.propTypes = {
  /** className passed to Icon */
  className: PropTypes.string,
  /** icon type  */
  type: PropTypes.oneOf(CXTypes).isRequired,
  /** Allow to custom webfont size */
  size: PropTypes.oneOf(['s', 'm', 'l']),
}

CXIcon.defaultProps = {
  size: 'm',
}

export { CXTypes }
export default CXIcon
