import React, { SyntheticEvent } from 'react'
import { IconButton } from '@nike/epic-react-ui'
import { disabledButton, themedButton } from '../commonStyles'

type RegisterButtonProps = {
  handleSubmit: (event: SyntheticEvent) => void
  type: string
  disabled?: boolean
}

export const RegisterButton = (props: RegisterButtonProps) => (
  <IconButton
    type='nike-logo'
    onClick={props.handleSubmit}
    aria-label='Register'
    small
    theme={props.disabled ? disabledButton : themedButton}
    disabled={props.disabled}
  >
    Register {props.type}
  </IconButton>
)
