import { Avatar, Container, Overlay } from '@EPIC'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'

import { DefaultLink } from '../util/index.js'
import SearchButton from './deps/SearchButton.js'
import styles from './styles.styl'

const keyGen = (...args) => args.join('-').trim()

const Hamburger = ({ onClick, activeRoute, logo, name }) => (
  <div className={styles.menuToggle} onClick={onClick}>
    <span className={styles.hamburger}>&#9776;</span>
    <span className={styles.menuRouteIdentifier}>
      {activeRoute ? (
        <div className={styles.routeIndicator}>
          <span className={`${styles.underline} ${styles.active}`}>{activeRoute.text}</span>
        </div>
      ) : (
        <div className={styles.logo}>
          {logo ? <img src={logo} alt={`collapse navbar ${name} logo`} /> : null}
          {name ? <span className={styles.title}>{name}</span> : null}
        </div>
      )}
    </span>
  </div>
)

const DocsLink = ({ url }) => <a href={url}>Docs</a>

const ExternalNavLink = ({ path, children, className = null }) => (
  <a href={path} className={className} target='_blank' rel='noopener noreferrer'>
    {children}
  </a>
)

const DropDownNavLinks = ({ RouterLink, title, links, navButtonStyles = '', className }) => (
  <div className={`${styles.dropdownSection} ${className || ''}`}>
    <div className={`${styles.dropdownButton} ${navButtonStyles}`}>{title}</div>
    <ul>
      {links.map(({ text, path, external }) => (
        <li className={`${styles.dropdownItem}`} key={keyGen(text, path)}>
          {external ? (
            <ExternalNavLink className={styles.dropdownLink} path={path}>
              {text}
            </ExternalNavLink>
          ) : (
            <RouterLink className={styles.dropdownLink} href={path}>
              {text}
            </RouterLink>
          )}
        </li>
      ))}
    </ul>
  </div>
)

const AvatarDropDown = ({ RouterLink, avatar, routes, className }) => (
  <div className={`${styles.avatarDropdown} ${className || ''}`}>
    <div className={`${styles.navbarAvatar}`}>
      {routes.imageLink ? <RouterLink href={routes.imageLink}>{avatar}</RouterLink> : avatar}
    </div>
    {routes.dropDownLinks ? (
      <ul className={`${styles.navbarDropdownSection}`}>
        {routes.dropDownLinks.map(({ text, path, external }) => (
          <li key={keyGen(text)}>
            {external ? (
              <ExternalNavLink path={path}>{text}</ExternalNavLink>
            ) : (
              <RouterLink href={path}>{text}</RouterLink>
            )}
          </li>
        ))}
      </ul>
    ) : null}
  </div>
)

class NavBarLinks extends Component {
  render() {
    const { routes, RouterLink } = this.props
    return routes.map(({ path, text, dropdownLinks, external, exact, className }) => {
      // Deal with the active route underline
      let active = ''
      if (exact) {
        active = window.location.pathname === path ? styles.active : ''
      } else if (path) {
        active = window.location.pathname.indexOf(path) !== -1 ? styles.active : ''
      }

      // Show a dropdown list
      if (dropdownLinks)
        return (
          <DropDownNavLinks
            key={keyGen(text, path)}
            title={text}
            links={dropdownLinks}
            RouterLink={RouterLink}
            className={className}
          />
        )

      // Show external link
      if (external)
        return (
          <ExternalNavLink key={keyGen(text, path)} path={path} className={className}>
            {text}
          </ExternalNavLink>
        )

      // Show default link
      return (
        <RouterLink
          key={keyGen(text, path)}
          href={path}
          onClick={() => this.setActivePath(path)}
          className={className}
        >
          <span className={`${styles.underline} ${active}`}>{text}</span>
        </RouterLink>
      )
    })
  }
}

const WithContainer = ({ condition, children }) =>
  condition ? <Container>{children}</Container> : children

class NavBar extends Component {
  state = {
    searchOpen: false,
    searchValue: '',
    routeKey: '',
    activePath: this.props.rootPath || '/',
    showMenu: false,
    menuEnabled: false,
  }

  static getDerivedStateFromProps(props) {
    return {
      menuEnabled: window.innerWidth < props.sideNavPxBreak,
    }
  }

  componentWillUnmount() {
    this.unregisterMediaEventListeners()
  }

  updateMediaEventListeners = () => {
    if (
      !this.disableSideNav &&
      (!this.mql || this.mql.media.indexOf(this.props.sideNavPxBreak) === -1)
    ) {
      this.unregisterMediaEventListeners()
      return this.registerMediaEventListeners()
    }
    return false
  }

  registerMediaEventListeners = () => {
    if (!this.props.disableSideNav) {
      this.mql =
        window.matchMedia &&
        window.matchMedia(`screen and (max-width: ${this.props.sideNavPxBreak}px)`)
      window.addEventListener('popstate', this.updateActivePathState, false)
      this.mql && this.mql.addListener(this.mediaQueryListener)

      // Prime the media match
      if (window.innerWidth < this.props.sideNavPxBreak) {
        return true
      }
    }
    return false
  }

  unregisterMediaEventListeners = () => {
    window.removeEventListener('popstate', this.updateActivePathState, false)
    this.mql && this.mql.removeListener(this.mediaQueryListener)
  }

  mediaQueryListener = (e) => {
    if (e.matches) {
      this.setState({ menuEnabled: true })
    } else {
      // If the media query doesn't match, shut it down
      ;(this.state.showMenu || this.state.menuEnabled) &&
        this.setState({ showMenu: false, menuEnabled: false })
    }
  }

  updateActivePathState = () => {
    this.setState({ activePath: window.location.pathname })
  }

  onHistory = (location) => {
    if (location && this.state.routeKey !== location.key) {
      this.setState({ routeKey: location.key })
    }
  }

  onClick = (target) => {
    if (this.state.searchOpen && target.id === 'er-nav-search-submit') {
      this.setState({ searchOpen: false })
      this.onSubmit()
    } else if (target.dataset.value === undefined) {
      this.setState({ searchOpen: true })
    }
  }

  onChange = (value) => {
    this.setState({ searchValue: value || '' }, () => {
      if (this.props.onChange) this.props.onChange(this.state.searchValue)

      // Clearing the input, if only onSubmit is registered
      if (!value && this.props.onSubmit) this.props.onSubmit(this.state.searchValue)
    })
  }

  onSubmit = (selectValue) => {
    if (selectValue) {
      this.setState({ searchValue: selectValue })
    }
    const value = selectValue || this.state.searchValue
    value.length > 0 && this.props.onSubmit && this.props.onSubmit(value)
  }

  navLink = (props) => {
    const { RouterLink } = this.props
    return (
      <RouterLink
        {...props}
        onClick={() => this.setState({ activePath: props.href, showMenu: false })}
      />
    )
  }

  toggleMenu = () => {
    this.setState({
      showMenu: this.state.menuEnabled && !this.state.showMenu,
    })
  }

  getActiveRoute = () => {
    let active = ''
    this.props.routes.forEach((route) => {
      if (
        (route.exact && window.location.pathname === route.path) ||
        (route.path && window.location.pathname.indexOf(route.path) !== -1)
      ) {
        active = route
      }
    })
    return active
  }

  isResponsiveNav = () => {
    // Will update in case of a props change will skip if already set
    this.navMediaRegistrationEnabled = this.updateMediaEventListeners()
    if (
      !this.props.disableSideNav &&
      (this.state.menuEnabled || this.navMediaRegistrationEnabled)
    ) {
      return true
    }
    return false
  }

  render() {
    const { searchOpen, searchValue, showMenu } = this.state
    const {
      name,
      logo,
      docsUrl,
      slack,
      slackURL,
      slackURLs,
      routes,
      onChange,
      onSubmit,
      showSearchBtnOnOpen,
      showSearchBtnFirst,
      searchText,
      searchPlaceholder,
      previewItems,
      aside,
      avatar,
      responsive,
    } = this.props
    const rootPath = this.props.rootPath || '/'
    const NavLink = this.navLink
    const activeRoute = this.getActiveRoute()
    const isResponsive = this.isResponsiveNav()

    return (
      <Fragment>
        <Overlay className={styles.fadeIn} show={this.state.showMenu} onClick={this.toggleMenu} />
        <div className={`${styles.navbar} ${isResponsive && styles.responsive}`}>
          <WithContainer condition={responsive}>
            <Hamburger
              onClick={this.toggleMenu}
              activeRoute={activeRoute}
              logo={logo}
              name={name}
            />
            <nav
              className={`${this.props.className || ''} ${styles.navigation} ${
                showMenu ? styles.show : ''
              }`}
            >
              <NavLink className={styles.logo} href={rootPath} aria-label='home link'>
                {logo ? <img src={logo} alt={`${name} logo`} /> : null}
                {name ? <span className={styles.title}>{name}</span> : null}
              </NavLink>

              <div className={styles.innerNav}>
                <NavBarLinks routes={routes} RouterLink={NavLink} rootPath={rootPath} />

                {docsUrl && <DocsLink url={docsUrl} />}

                {((slack && slack.urls) || slackURLs) && (
                  <DropDownNavLinks
                    RouterLink={NavLink}
                    links={(slack && slack.urls ? slack.urls : slackURLs).map((l) => ({
                      ...l,
                      external: true,
                    }))}
                    title={<i className='epic-icon epic-icon-slack-with-name' />}
                    navButtonStyles={`${styles.appSlack} ${styles.appSlackDropdown}`}
                    className={slack && slack.className}
                  />
                )}

                {((slack && slack.url) || slackURL) && (
                  <ExternalNavLink
                    path={slack && slack.url ? slack.url : slackURL}
                    className={`${styles.appSlack} ${(slack && slack.className) || ''}`}
                  >
                    <i className='epic-icon epic-icon-slack-with-name' />
                  </ExternalNavLink>
                )}

                {onChange || onSubmit ? (
                  <SearchButton
                    previewItems={previewItems}
                    searchOpen={showMenu || searchOpen}
                    showButtonFirst={showSearchBtnFirst}
                    searchValue={searchText !== undefined ? searchText : searchValue}
                    onClick={(e) => this.onClick(e.target)}
                    onChange={(e) => this.onChange(e.target.value)}
                    onSubmit={(selectValue) => this.onSubmit(selectValue)}
                    onClose={() => this.setState({ searchOpen: false, searchValue: '' })}
                    showButtonOnOpen={showSearchBtnOnOpen}
                    placeholder={searchPlaceholder}
                  />
                ) : null}
              </div>

              <div className={styles.asideContainer}>
                {aside && aside}
                {avatar && (
                  <AvatarDropDown
                    RouterLink={NavLink}
                    routes={avatar.routes}
                    className={avatar.className}
                    avatar={<Avatar {...avatar} small />}
                  />
                )}
              </div>
            </nav>
          </WithContainer>
        </div>
      </Fragment>
    )
  }
}

NavBar.propTypes = {
  /** Application Name */
  name: PropTypes.string,
  /** http url to logo */
  logo: PropTypes.string,
  /** Route objects that describe a react-router route */
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      /** Route path */
      path: PropTypes.string,
      /** Text to display for the route */
      text: PropTypes.string.isRequired,
      /** Search value for nav route */
      search: PropTypes.string,
      /** Match the route exact: true [default] OR false */
      exact: PropTypes.bool,
      /** Should render as external anchor tag */
      external: PropTypes.bool,
      /** Option to have dropdown list */
      dropdownLinks: PropTypes.array,
      /** class applied to dropdown button */
      className: PropTypes.string,
    })
  ).isRequired,
  /**
   * Slack item configuration
   * @param {any} props props for component.
   * @param {number} _ disregard.
   * @param {number} componentName Name of the component.
   * @return {any} somthing
   */
  slack: (props, _, componentName) => {
    if (props.url && props.urls) {
      return new Error(`Must supply prop 'url' or 'urls' to ${componentName} but not both`)
    }

    if (props.url) {
      PropTypes.checkPropTypes(
        {
          url: PropTypes.string,
          className: PropTypes.string,
        },
        { url: props.url, className: props.className },
        'prop',
        componentName
      )
    }

    if (props.urls) {
      PropTypes.checkPropTypes(
        {
          urls: PropTypes.arrayOf(
            PropTypes.shape({
              path: PropTypes.string,
              text: PropTypes.string.isRequired,
            })
          ),
          className: PropTypes.string,
        },
        { urls: props.urls, className: props.className },
        'prop',
        componentName
      )
    }

    return null
  },
  /** Legacy slack URL prop */
  slackURL: PropTypes.string,
  /** Legacy slack dropdown URLs prop */
  slackURLs: PropTypes.arrayOf(
    PropTypes.shape({
      /** path of link */
      path: PropTypes.string,
      /** link text */
      text: PropTypes.string.isRequired,
    })
  ),
  /** http url to external documentation */
  docsUrl: PropTypes.string,
  /** To control the text value of the search, just provide a value */
  searchText: PropTypes.string,
  /** Placeholder text for search bar */
  searchPlaceholder: PropTypes.string,
  /** Search onChange value */
  onChange: PropTypes.func,
  /** Search onSubmit value; will execute if search input is cleared */
  onSubmit: PropTypes.func,
  /** The root path to the application (eg / or /some value) */
  rootPath: PropTypes.string,
  /** Show search button when search input is open {default is true} */
  showSearchBtnOnOpen: PropTypes.bool,
  /** Search button position */
  showSearchBtnFirst: PropTypes.bool,
  /** Escape hatch for styling */
  className: PropTypes.string,
  /** React Router history instance */
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  /** Search preview items */
  previewItems: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
      })
    ),
  ]),
  /** Based on the react router you are using pass in the Link component */
  RouterLink: PropTypes.func,
  /** Right side of navbar */
  aside: PropTypes.object,
  /** Option to have a right side avatar component */
  avatar: PropTypes.shape({
    /** Path of avatar image */
    imgSrc: PropTypes.string,
    /** Email for Gravatar image */
    email: PropTypes.string,
    /** Badge count */
    badgeCount: PropTypes.number,
    /** Option to have routed avatar  */
    routes: PropTypes.shape({
      /** Option to have an image link */
      imageLink: PropTypes.string,
      /** Option to have dropdown list */
      dropDownLinks: PropTypes.array,
    }),
    /** Option to have active highlight around avatar */
    isActive: PropTypes.bool,
  }),
  /** Wrap the inner nav component in a responsive container */
  responsive: PropTypes.bool,
  /** Disables side nav when screen size is reduces below 1024px; default nav will be displayed */
  disableSideNav: PropTypes.bool,
  /** Side Nav activation threshold in pixels */
  sideNavPxBreak: PropTypes.number,
}

NavBar.defaultProps = {
  showSearchBtnOnOpen: true,
  showButtonFirst: false,
  searchPlaceholder: 'Search...',
  responsive: false,
  disableSideNav: false,
  sideNavPxBreak: 1024,
  RouterLink: DefaultLink,
}

export default NavBar
