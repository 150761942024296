import classnames from 'classnames'
import { isNil } from 'lodash'
import PropTypes from 'prop-types'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import {
  actions,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'

import {
  BulkSelectRow,
  IndeterminateCheckbox,
  TableContainer,
  TableHeader,
  Tbody,
} from './CommonTableComponents.js'
import {
  ColumnFilterButton,
  FilterButton,
  FilterSection,
  LoadingRow,
  NoDataRow,
  Pagination,
  PaginationControls,
  SearchBar,
} from './PagedFilterTableComponents.js'
import styles from './styles.styl'
import { setMaxHeight } from './Table.js'

const PaginationControlledTable = forwardRef(
  (
    {
      className,
      columns,
      data,
      withSearchBar,
      withColumnFilter,
      searchBarPlaceholder,
      searchBarLabel,
      maxLength,
      onTableChange,
      loading,
      pageCount: controlledPageCount,
      pageSizeOptions,
      isCompact,
      initialState,
      defaultFilters,
      filters,
      onFiltersChange,
      totalRowCount,
      withSelectableRows,
      onSelectedRowChange,
      BulkSelectRowAside,
      emptyTitle,
      emptySubtitle,
      getRowId,
      autoResetSelectedRows = true,
      autoResetPage = false,
      visibleRowsCount,
      ...props
    },
    ref
  ) => {
    const {
      state: { globalFilter, pageIndex, pageSize, sortBy, selectedRowIds },
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      setGlobalFilter,
      selectedFlatRows,
      toggleAllPageRowsSelected,
      getToggleHideAllColumnsProps,
      allColumns,
      dispatch,
    } = useTable(
      {
        columns,
        data,
        initialState: {
          pageSize: pageSizeOptions.length > 0 ? pageSizeOptions[0] : 10, // Default to `pageSizeOptions` first item
          ...initialState,
        },

        pageCount: controlledPageCount,
        manualPagination: true,

        manualGlobalFilter: true,
        manualSortBy: true,

        autoResetPage,
        autoResetSortBy: false,
        autoResetSelectedRows,
        getRowId,
      },
      useGlobalFilter,
      useSortBy,
      usePagination,
      useRowSelect,
      (hooks) => {
        if (withSelectableRows) {
          hooks.visibleColumns.push((columns) => [
            {
              id: 'selection',
              Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
              ),
              Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
            },
            ...columns,
          ])
        }
      }
    )

    // Listen for changes in pagination and use the state to fetch our new data
    useEffect(() => {
      onTableChange?.({ pageIndex, pageSize, globalFilter, sortBy })
    }, [onTableChange, pageIndex, pageSize, globalFilter, sortBy])

    useImperativeHandle(ref, () => ({
      resetAllSelectedRows: () => dispatch({ type: actions.resetSelectedRows }),
      resetPageIndex: () => dispatch({ type: actions.gotoPage, pageIndex: 0 }),
    }))

    const [showFilters, setShowFilters] = useState(false)
    const [showAllFilters, setShowAllFilters] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState(defaultFilters || {})
    const [filterFilterQueries, setFilterFilterQueries] = useState({})
    const [columnFilterVisible, setColumnFilterVisible] = useState(false)
    const [columnFilterQuery, setColumnFilterQuery] = useState('')

    useEffect(() => {
      onFiltersChange?.(selectedFilters)
    }, [onFiltersChange, selectedFilters])

    const setFilters = (newFilterState) => {
      setSelectedFilters(newFilterState)
    }
    const handleChangeSelectedFilters = (filterGroup, event) => {
      if (!selectedFilters[filterGroup]) selectedFilters[filterGroup] = []
      setFilters({
        ...selectedFilters,
        [filterGroup]: event.target.checked
          ? [...selectedFilters[filterGroup], event.target.name]
          : [...selectedFilters[filterGroup]].filter((item) => item !== event.target.name),
      })
    }
    const handleSelectAllFilters = (filterGroup, childFilterArray) => {
      setFilters({
        ...selectedFilters,
        [filterGroup]: childFilterArray.map((item) => item.accessor),
      })
    }
    const handleClearSelectedFilters = (filterGroup) => {
      if (filterGroup) {
        setFilters({
          ...selectedFilters,
          [filterGroup]: [],
        })
      } else {
        setFilters({})
      }
    }
    const handleUpdateFilterFilterQuery = (filterGroup, e) => {
      setFilterFilterQueries({
        ...filterFilterQueries,
        [filterGroup]: e.target.value,
      })
    }

    const searchBarProps = {
      globalFilter,
      setGlobalFilter,
      searchBarPlaceholder,
      searchBarLabel,
      maxLength,
    }

    const paginationProps = {
      nextPage,
      previousPage,
      canNextPage,
      canPreviousPage,
      pageIndex,
      pageOptions,
      totalRowCount,
      gotoPage,
      pageCount,
      pageSize,
      setPageSize,
      pageSizeOptions,
      pageNumbersOptions: [...Array(pageCount)].map((_, num) => ({
        value: num.toString(),
        label: `${num + 1}`.toString(),
      })),
      ...props,
    }

    const headerProps = {
      headerGroups,
      controledPagination: true,
      isCompact,
    }

    const bodyProps = {
      page,
      prepareRow,
      isCompact,
    }

    const clearSelectedRows = () => toggleAllPageRowsSelected(false)

    const bulkSelectProps = {
      selectedRowIds,
      selectedFlatRows,
      toggleAllRowsSelected: toggleAllPageRowsSelected,
      clearSelectedRow: clearSelectedRows,
    }

    useEffect(() => {
      withSelectableRows &&
        onSelectedRowChange &&
        onSelectedRowChange({ selectedRowIds, selectedFlatRows, clearSelectedRows })
    }, [selectedFlatRows.length])

    const filterCount = useMemo(
      () =>
        Object.keys(selectedFilters).reduce((acc, curr) => acc + selectedFilters[curr].length, 0),
      [selectedFilters]
    )

    const handleFilterBtnClick = useCallback(() => setShowFilters(!showFilters), [showFilters])

    return (
      <div {...className}>
        <div className={styles.searchFilterSection}>
          {withSearchBar && <SearchBar {...searchBarProps} />}
          {!!filters.length && (
            <FilterButton
              showFilters={showFilters}
              handleFilterBtnClick={handleFilterBtnClick}
              filterCount={filterCount}
            />
          )}
          {withColumnFilter && (
            <ColumnFilterButton
              columns={allColumns}
              toggleAll={getToggleHideAllColumnsProps}
              columnFilterVisible={columnFilterVisible}
              setColumnFilterVisible={setColumnFilterVisible}
              columnFilterQuery={columnFilterQuery}
              setColumnFilterQuery={setColumnFilterQuery}
              withSelectableRows={withSelectableRows}
            />
          )}
        </div>
        {showFilters ? (
          <FilterSection
            selectedFilters={selectedFilters}
            handleChangeSelectedFilters={handleChangeSelectedFilters}
            handleSelectAllFilters={handleSelectAllFilters}
            handleClearSelectedFilters={handleClearSelectedFilters}
            showAllFilters={showAllFilters}
            setShowAllFilters={setShowAllFilters}
            filters={filters}
            filterFilterQueries={filterFilterQueries}
            handleUpdateFilterFilterQuery={handleUpdateFilterFilterQuery}
          />
        ) : null}

        {selectedFlatRows.length > 0 && (
          <BulkSelectRow {...bulkSelectProps}>
            {BulkSelectRowAside && <BulkSelectRowAside {...bulkSelectProps} />}
          </BulkSelectRow>
        )}

        {!loading && data.length === 0 ? (
          <NoDataRow title={emptyTitle} subtitle={emptySubtitle} />
        ) : (
          <TableContainer
            className={classnames({
              [styles.scrollTable]: !isNil(visibleRowsCount) && visibleRowsCount > 0,
              [styles.withCheckboxColumn]: withSelectableRows,
              [styles.bulkSelectRowActive]: withSelectableRows && selectedFlatRows.length > 0,
            })}
            {...getTableProps()}
          >
            <>
              <TableHeader {...headerProps} />
              <Tbody {...getTableBodyProps()} style={setMaxHeight(visibleRowsCount, isCompact)}>
                {loading ? <LoadingRow isCompact /> : <Pagination {...bodyProps} />}
              </Tbody>
            </>
          </TableContainer>
        )}

        {data.length !== 0 && <PaginationControls {...paginationProps} />}
      </div>
    )
  }
)

PaginationControlledTable.propTypes = {
  /** Allow custom className to the component */
  className: PropTypes.string,
  /** (You should wrap this array with the `useMemo` hook) Column headers (`Header` value is the text that will show up in the table & `accessor` value is which value shows up in the cell)*/
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      accessor: PropTypes.string.isRequired,
      defaultCanSort: PropTypes.bool,
      disableSortBy: PropTypes.bool,
      sortDescFirst: PropTypes.bool,
      sortInverted: PropTypes.bool,
      sortType: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    })
  ).isRequired,
  /** (You should wrap this array with the `useMemo` hook) Array of data with `accessor` as the keys  */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Allow for table text search */
  withSearchBar: PropTypes.bool,
  /** Max character limit for search control */
  maxLength: PropTypes.number,
  /** Placeholder text to show up in the Table searchBar */
  searchBarPlaceholder: PropTypes.string,
  /** Label text to show up in the Table searchBar */
  searchBarLabel: PropTypes.string,
  /** Less padding around the Header & Body's cells */
  isCompact: PropTypes.bool,
  /** Set the initial state of the table */
  initialState: PropTypes.object,
  /** Object that provides the table with the filters to be selected by default, example: `{ filterName: ['valueSelected']  }` */
  defaultFilters: PropTypes.object,
  /** (PaginationTable & PaginationControlledTable) Set the number of options to list view amount of rows in the table */
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  /** (PaginationControlledTable only) Manually set the loading state */
  loading: PropTypes.bool.isRequired,
  /** (PaginationControlledTable only) This function will be called whenver the table changes*/
  onTableChange: PropTypes.func,
  /** Total data size */
  totalRowCount: PropTypes.number,

  /** Show rows with a selectable checkbox that when an item is clicked it will show a bulk select row above the table */
  withSelectableRows: PropTypes.bool,
  /** Get the selected */
  onSelectedRowChange: PropTypes.func,
  /** Pass in a Component to be shown in the Bulk Action Row with access to `selectedRowIds`, `selectedFlatRows`, & `clearSelectedRows` parameters */
  BulkSelectRowAside: PropTypes.func,

  /** Object of filter properties */
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          accessor: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  /** Function called when selected filters change, is passed selected filter state */
  onFiltersChange: PropTypes.func,
  /** Allow for column filtering */
  withColumnFilter: PropTypes.bool,
  /** Set the Title for the table's empty/no-data state */
  emptyTitle: PropTypes.string,
  /** Set the Subtitle for the table's empty/no-data state */
  emptySubtitle: PropTypes.string,
  /** Function provided to obtain the row id by a specific accessor */
  getRowId: PropTypes.func,
  /** Automatically reset selected rows */
  autoResetSelectedRows: PropTypes.bool,
  /** Automatically reset selected page */
  autoResetPage: PropTypes.bool,
  /** Allow for body table scrolling, number of rows to show */
  visibleRowsCount: PropTypes.number,
}

PaginationControlledTable.defaultProps = {
  className: '',
  columns: [],
  data: [],
  withSearchBar: false,
  searchBarPlaceholder: 'Search',
  searchBarLabel: '',
  isCompact: false,
  initialState: {},
  filters: [],
  onFiltersChange: null,
  totalRowCount: 0,
  pageSizeOptions: [10, 20, 25],
  loading: true,
  withSelectableRows: false,
  withColumnFilter: false,
}
export default PaginationControlledTable
