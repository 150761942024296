import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Collapsible from 'react-collapsible'

import styles from './styles.styl'

const trigger = (isOpen) => (
  <span className={`${styles.collapseIcon} ${isOpen ? styles.minus : styles.plus}`}>
    {isOpen ? '-' : '+'}
  </span>
)

const Section = ({
  title,
  children,
  subtitle,
  className,
  collapsible,
  collapsibleConfig,
  ...props
}) => {
  let sectionClassName = className ? `${styles.section} ${className}` : styles.section
  let sectionClass = classnames(styles.sectionContent)
  if (!(title || subtitle)) sectionClass = classnames(styles.fullContent)

  return (
    <section>
      <div {...props} className={sectionClassName}>
        {(title || subtitle) && (
          <div className={styles.header}>
            {title && <h1>{title}</h1>}
            {subtitle && <h3>{subtitle}</h3>}
          </div>
        )}
        {collapsible ? (
          <Collapsible
            trigger={trigger(false)}
            triggerWhenOpen={trigger(true)}
            {...collapsibleConfig}
          >
            <div className={sectionClass}>{children}</div>
          </Collapsible>
        ) : (
          <div className={sectionClass}>{children}</div>
        )}
      </div>
    </section>
  )
}

Section.propTypes = {
  /** className passed to Section */
  className: PropTypes.string,
  /** Title Header for Section */
  title: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** SubTitle Header for Section */
  subtitle: PropTypes.string,
  /** SubTitle Header for Section */
  collapsible: PropTypes.bool,
  /** Pass config to react-collapsible */
  collapsibleConfig: PropTypes.object,
  /** Children */
  children: PropTypes.PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

Section.defaultProps = {
  className: '',
  collapsibleConfig: {},
}

export default Section
