import { ActionInput } from '@EPIC'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { DefaultLink } from '../../util/index.js'
import ChildrenFocusBlur from './ChildrenFocusBlur.js'
import styles from './styles.styl'

const keyGen = (...args) => args.join('-').trim()
const ExternalNavLink = ({ path, children, className = null }) => (
  <a href={path} className={className} target='_blank' rel='noopener noreferrer'>
    {children}
  </a>
)

function NavBarLinks({ routes, RouterLink, activeRoute, fixed, menuType }) {
  const [focusedLink, setFocusedLink] = useState('')

  return routes.map(({ path, text, external, exact, title, menuItems, className }) => {
    const uniqueId = keyGen(text, path)

    let activeClass = ''
    if (exact) {
      activeClass = activeRoute === path ? styles.active : ''
    } else if (path) {
      activeClass = activeRoute.includes(path) ? styles.active : ''
    }

    const handleFocus = () => {
      setFocusedLink(uniqueId)
    }

    const handleFocusClear = () => {
      setFocusedLink('')
    }

    const isExternal = !!external
    const isSubMenu = !!menuType && !!menuItems
    const isDefault = !isExternal && !isSubMenu

    return (
      <ChildrenFocusBlur
        key={uniqueId}
        onFocus={handleFocus}
        onBlur={handleFocusClear}
        onClick={handleFocusClear}
      >
        {isExternal && (
          <ExternalNavLink path={path} className={className}>
            <span className={styles.link}>{text}</span>
          </ExternalNavLink>
        )}

        {isSubMenu && (
          <div
            className={classnames(
              { [styles.subNavMega]: menuType === 'mega' },
              { [styles.subNavExpand]: menuType === 'expand' }
            )}
          >
            <SubNav
              title={title}
              menuItems={menuItems}
              RouterLink={RouterLink}
              fixed={fixed}
              isMega={menuType === 'mega'}
              isParentFocused={focusedLink === uniqueId}
            >
              <RouterLink href={path} className={className}>
                <span className={classnames(styles.underline, styles.link, activeClass)}>
                  {text}
                </span>
              </RouterLink>
            </SubNav>
          </div>
        )}

        {isDefault && (
          <RouterLink href={path} className={className}>
            <span className={classnames(styles.underline, styles.link, activeClass)}>{text}</span>
          </RouterLink>
        )}
      </ChildrenFocusBlur>
    )
  })
}

function SubNav({ title, menuItems, RouterLink, children, fixed, isMega, isParentFocused }) {
  const [scrollOffset, setScrollOffset] = useState(0)
  const [subNavOpen, setSubNavOpen] = useState(false)

  const handleOpenSubNav = () => {
    setSubNavOpen(true)
    if (!fixed) {
      document.body.style.overflowY = 'hidden'
      setScrollOffset(window.scrollY)
    }
  }

  const handleCloseSubNav = () => {
    setSubNavOpen(false)
    if (!fixed) {
      document.body.style.overflowY = 'auto'
    }
  }

  const groupedMenuItems = []
  for (let i = 0; i < menuItems.length; i += 10) {
    groupedMenuItems.push(menuItems.slice(i, i + 10))
  }

  return (
    <div
      className={classnames(styles.subNavWrapper, {
        [styles.hover]: subNavOpen || isParentFocused,
      })}
      onMouseEnter={handleOpenSubNav}
      onMouseLeave={handleCloseSubNav}
      onClick={handleCloseSubNav}
    >
      {children}
      <div className={classnames(styles.subNavBg, { [styles.subNavBgOverlay]: isMega })}>
        <div
          className={classnames({ [styles.subNavBg]: isMega })}
          style={{
            transform: `translateY(-${scrollOffset}px)`,
          }}
        >
          <div className={styles.subNavContent}>
            {groupedMenuItems.map((group, i) => {
              return (
                <div key={i} className={styles.subNavColumn}>
                  {title && <div className={styles.subNavTitle}>{i === 0 && <h4>{title}</h4>}</div>}
                  {group.map(({ text, path }) => {
                    return (
                      <RouterLink
                        key={keyGen(text, path)}
                        className={styles.subNavLink}
                        href={path}
                      >
                        <span>{text}</span>
                      </RouterLink>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

function NavBar({
  aside,
  className,
  fixed,
  inverse,
  logo,
  menuType,
  name,
  onSearch,
  rootPath,
  RouterLink,
  routes,
  searchCleanup,
  searchPlaceholder,
}) {
  const [activeRoute, setActiveRoute] = useState(window.location.pathname)
  const [searchValue, setSearchValue] = useState('')
  useEffect(() => setActiveRoute(window.location.pathname), [window.location.pathname])
  console.log('NavBar', activeRoute)

  const searchInputRef = React.useRef()

  const navClassName = classnames(styles.navbar, className, {
    [styles.inverse]: inverse,
  })

  function NavLink(props) {
    let Component = RouterLink || DefaultLink
    return (
      <Component
        {...props}
      />
    )
  }

  function handleSearch() {
    onSearch?.(searchValue)
    if (searchCleanup) {
      setSearchValue('')
      searchInputRef.current.value = ''
    }
  }

  function handleChange(e) {
    setSearchValue(e?.currentTarget?.value || '')
  }

  return (
    <div className={classnames(navClassName, { [styles.fixed]: fixed })}>
      <nav className={styles.navigation}>
        <NavLink className={styles.logo} href={rootPath} aria-label='home link'>
          {logo &&
            (typeof logo === 'string' ? (
              <img className={className} src={logo} alt={`${name} logo`} />
            ) : (
              logo
            ))}
          {name ? <span className={styles.title}>{name}</span> : null}
        </NavLink>

        <div className={styles.innerNav}>
          <NavBarLinks
            activeRoute={activeRoute}
            fixed={fixed}
            menuType={menuType}
            rootPath={rootPath}
            RouterLink={NavLink}
            routes={routes}
          />
        </div>
        {(onSearch || aside) && (
          <div className={styles.asideContainer}>
            {onSearch && (
              <div
                className={classnames(styles.searchContainer, { [styles.asideSidePad]: !aside })}
              >
                <ActionInput
                  ariaLabel='Search'
                  onAction={handleSearch}
                  onChange={handleChange}
                  placeholder={searchPlaceholder}
                  submitOnEnter
                  ref={searchInputRef}
                />
              </div>
            )}
            {aside && <div>{aside}</div>}
          </div>
        )}
      </nav>
    </div>
  )
}

NavBar.propTypes = {
  /** Application Name */
  name: PropTypes.string,
  /** Logo (http url or node) */
  logo: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Route objects that describe a react-router route */
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      /** Route path */
      path: PropTypes.string,
      /** Text to display for the route */
      text: PropTypes.string.isRequired,
      /** Search value for nav route */
      search: PropTypes.string,
      /** Match the route exact: true [default] OR false */
      exact: PropTypes.bool,
      /** Should render as external anchor tag */
      external: PropTypes.bool,
      /** class applied to dropdown button */
      className: PropTypes.string,
      /** Display header for section menu */
      title: PropTypes.string,
      /** optional links to additional render in sub nav menu */
      menuItems: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  /** The root path to the application (eg / or /some value) */
  rootPath: PropTypes.string,
  /** Escape hatch for styling */
  className: PropTypes.string,
  /** Based on the react router you are using pass in the Link component */
  RouterLink: PropTypes.elementType,
  /** Right side of navbar */
  aside: PropTypes.object,
  /** Makes primary color black, secondary white */
  inverse: PropTypes.bool,
  /** Function to run on search action, enables search input */
  onSearch: PropTypes.func,
  /** Optional placeholder for search input */
  searchPlaceholder: PropTypes.string,
  /** Clear search input after search */
  searchCleanup: PropTypes.bool,
  /** NavBar is fixed to the top of the window */
  fixed: PropTypes.bool,
  /** Controls how `menuItems` supplied in `routes` should be rendered */
  menuType: PropTypes.oneOf([false, 'expand', 'mega']),
}

NavBar.defaultProps = {
  menuType: 'mega',
  rootPath: '/',
  routes: [],
  searchCleanup: true,
  searchPlaceholder: 'Search',
}

export default NavBar
