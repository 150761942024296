import React from 'react'
import PropTypes from 'prop-types'
import { containerStyleLarge, containerStyleSmall } from '../commonStyles'
interface SpinnerProps {
  large: boolean
  className: string
}

const Spinner = ({ large, className }: SpinnerProps) => {
  const containerStyle = large ? containerStyleLarge : containerStyleSmall
  return (
    <div style={containerStyle}>
      <div className={`spinner ${large ? 'spinner-lg' : ''} ${className}`} />
    </div>
  )
}

Spinner.defaultProps = {
  large: false,
  className: '',
}

Spinner.propTypes = {
  large: PropTypes.bool,
  className: PropTypes.string,
}

export default Spinner
