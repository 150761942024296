import React, { ChangeEvent, useEffect, useState } from 'react'
import { NextGen, Button } from '@nike/epic-react-ui'
import { deleteButton, themedButton } from '../commonStyles'

interface MultiInputProps {
  title: string
  values: any[]
  handleChange: (values: any[]) => void
  validator: (value: string) => boolean
  errorMessage: string
}

const MultiInput = (props: MultiInputProps) => {
  const {
    title,
    values,
    handleChange,
    validator,
    errorMessage
  } = props

  const [inputValue, setInputValue] = useState<string>('')

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }
  const handleAdd = () => {
    if (!inputValue || values.includes(inputValue)) {
      setInputValue('')
      return
    }
    
    const newValues = [
      ...values,
      inputValue
    ]

    setInputValue('')
    handleChange(newValues)
  }

  const handleRemove = (name: string) => {
    const newValues = values.filter(value => value !== name)
    handleChange(newValues)
  }

  return (
    <div style={{ marginBottom: 15 }}>
      <div style={{ marginBottom: 15 }}>
        <NextGen.TextInput
          name={title}
          value={inputValue}
          hasErrors={inputValue && !validator(inputValue)}
          errorMessage={errorMessage}
          label={title}
          aria-label={title}
          onChange={handleInputChange}
        />
        <Button
          onClick={handleAdd}
          disabled={!validator(inputValue)}
          small
          theme={themedButton}
        >
          Add
        </Button>
      </div>
      <div>
        {values.map(value => (
          <Button
            small
            key={value}
            onClick={() => handleRemove(value)}
            theme={deleteButton}
          >
            {value} x
          </Button>
        ))}
      </div>
    </div>
  )
}

export default MultiInput
