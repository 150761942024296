/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react"

export const largeText = css`
  font-size: 22px;
  padding: 10px;
  text-align: left;
`

export const mediumText = {
  fontSize: 14,
  padding: 10
}

export const smallPadding = css`
  padding: 10px;
`

export const smallMargin = css`
  margin-left: 10px;
`

export const buttonGroupStyle = {
  marginTop: 15
}

export const disabled = css`
  opacity: 0.5;
`

export const accentButton = css`
  background-color: #fa5400;
  margin-left: 10px;
`

export const themedButton = {
  primary: "#5197D6",
  secondary: "white",
}

export const disabledButton = {
  primary: "grey",
  secondary: "white",
  opacity: 0.5
}

export const deleteButton = {
  primary: 'grey',
  secondary: 'white',
  [':hover']: {
    primary: 'red'
  }
}

export const deviceDetailsModalOuter = css`
  max-height: 60vh;
  overflow: hidden;
`
export const deviceDetailsModalInner = css`
  overflow-y: scroll;
  height: 30vh;
`

export const containerStyleLarge = {
  position: 'relative',
  width: '10rem',
  height: '10rem',
  margin: 'auto'
}
export const containerStyleSmall = {
  width: '1rem',
  height: '1rem',
  margin: 'auto'
}
