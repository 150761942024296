import isUrl from 'is-url'
import { URL_PARAMETERS, URL_PARAMETERS_LIST } from './constants'

export const nameValidationMessage = 'Value must be 200 characters or less and contain only: a-z A-Z 0-9 _ -'

export const gtinValidationMessage = 'GTIN must be 12 characters and can only contain numeric characters.'

export const styleColorCodeValidationMessage = 'Must have format STYLE_CODE-COLOR_CODE, where STYLE_CODE is 6 alphamueric characters and COLOR_CODE is 3 numeric characters. ex. CZ1622-010'

export const styleCodeValidationMessage = 'Style codes must be 6 alphamueric character ex. CZ1622'

export const urlValidationMessage = `Redirect url must be valid url. Path parameters must be of format {PARAMETER} where PARAMETER is from the list of accepted parameters: ${URL_PARAMETERS_LIST}`

export const qrValidationMessage = 'Valid Nike QR must be 13 characters long and only contain alphanumeric characters.'

export const priorityValidationMessage = 'Priority must be a non-zero integer'

const ruleNameRegex = new RegExp('^[a-zA-Z0-9_-]+$')

const urlCharacterRegex = new RegExp('^[/.{}:a-zA-Z0-9_-]+$')

const gtinRegex = new RegExp('^[0-9]+$')

const qrRegex = new RegExp('^[A-Z0-9]+$')

const styleCodeRegex = new RegExp('^[a-zA-Z0-9]+$')

const colorCodeRegex = new RegExp('^[0-9]+$')

export function validateName (value: string) {
  // 200 characters is arbitrary name length limit
  return !!(value.match(ruleNameRegex) && value.length <= 200)
}

export function validateUrl (value: string) {
  const validUrl = isUrl(value)

  if (!validUrl) {
    return false
  }

  const hasValidParameters = validateUrlParameters(value)

  return !!(value.match(urlCharacterRegex)) && hasValidParameters
}

export function validateGtin (value: string) {
  return !!(value.match(gtinRegex) && value.length === 14)
}

export function validateQR (value: string) {
  return !!(value.match(qrRegex) && value.length === 13)
}

export function validateStyleColorCode (value: string) {
  const codeParts = value.split('-')

  if (codeParts.length !== 2) {
    return false
  }

  const styleCode = codeParts[0]
  const colorCode = codeParts[1]

  const validStyleCode = styleCode.match(styleCodeRegex) && styleCode.length === 6
  const validColorCode = colorCode.match(colorCodeRegex) && colorCode.length === 3

  return !!(validStyleCode && validColorCode)
}

export function validateStyleCode (styleCode: string) {
  const validStyleCode = styleCode.match(styleCodeRegex) && styleCode.length === 6
  return !!validStyleCode
}

export function validatePriority (value: string) {
  const numericValue = Number(value)
  return Number.isInteger(numericValue) && numericValue > 0
}

export function getPathParameters (url: string): string[] {
  const found: string[] = []
  const regex = /{([^}]+)}/g
  let currentMatch: RegExpExecArray | null

  while ((currentMatch = regex.exec(url)) !== null) {
    found.push(currentMatch[0].slice(1, -1))
  }

  return found
}

export function validatePathParameters (pathParameters): string[] {
  const invalidParamters = pathParameters.filter(param => !URL_PARAMETERS.includes(param))
  return invalidParamters
}

export function validateUrlParameters (url: string): boolean {
  const pathParameters = getPathParameters(url)
  const invalidParamters = validatePathParameters(pathParameters)
  return !invalidParamters.length
}

export function noValidationNecessary () {
  return true
}