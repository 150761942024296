import merge from 'deepmerge'

const base = {
  appName: 'qr-experience-ui',
  appFullName: 'QR Experiences',
  oAuth: {
    scope: 'profile openid email connected_product:connectedid.justscanit.readonly::read: connected_product:connectedid.justscanit.admin::update: connected_product:connectedid.justscanit.admin::delete: connected_product:connectedid.justscanit.admin::create:',
    client_id: 'nike.niketech.just-scan-it',
    response_type: 'authorization_code',
    redirect_uri: window.location.origin + '/'
  }
}

const dev = merge(base, {
  apiUrl: 'https://just-scan-it-api-dev.sensors-test.nikecloud.com',
  oAuth: {
    authorization: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/v1/authorize',
    token: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/v1/token'
  }
})

const preprod = merge(base, {
  apiUrl: 'https://just-scan-it-api-preprod.sensors-test.nikecloud.com',
  oAuth: {
    authorization: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/v1/authorize',
    token: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/v1/token'
  }
})

const prod = merge(base, {
  apiUrl: 'https://just-scan-it-api.sensors.nikecloud.com',
  oAuth: {
    authorization: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/v1/authorize',
    token: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/v1/token'
  }
})

const isDev = () =>
  window.location.origin.includes('dev.sensors-test.nikecloud') ||
  window.location.origin.includes('localhost:')

const isPreProd = () => (
  window.location.origin.includes('preprod.sensors-test.nikecloud')
)

let config
if (isPreProd()) {
  console.log('Preprod')
  config = preprod
} else if (isDev()) {
  console.log('Dev')
  console.log(window.location.origin)
  config = dev
} else {
  console.log('Prod')
  config = prod
}
export default config
