/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect, useState } from "react"
import { css, jsx } from "@emotion/react"
import { Section } from "@nike/epic-react-ui"
import DateTimePicker from 'react-datetime-picker'
import { navigate } from 'raviger'
import { createRule, updateRule } from "../util/apiUtil"
import { 
  CATEGORY_OPTIONS,
  COUNTRY_OPTIONS,
  DIVISION_OPTIONS,
  EMEA_COUNTRY_CODES,
  GREATER_CHINA_COUNTRY_CODES,
  URL_PARAMETER_OPTIONS,
} from "../constants"
import {
  gtinValidationMessage,
  nameValidationMessage,
  noValidationNecessary,
  priorityValidationMessage,
  qrValidationMessage,
  styleCodeValidationMessage,
  styleColorCodeValidationMessage,
  urlValidationMessage,
  validateGtin,
  validateName,
  validatePriority,
  validateQR,
  validateStyleCode,
  validateStyleColorCode,
  validateUrl
} from "../validation"
import MultiSelect from "../components/MultiSelect"
import MultiInput from "../components/MultiInput"
import { Action } from '../types'
import { RegisterButton } from "../components/RegisterButton"
import RestrictedTextInput from "../components/RestrictedTextInput"
import UrlInput from "../components/UrlInput"

interface RegistrationFormProps {
  title: string
  action: Action,
  rule?: RuleFormValues,
  onRegistrationComplete?: (registrationSuccessful: boolean) => void,
  author?: string
}

export interface RuleFormValues {
  name: string
  priority: string
  redirectUrl: string
  author?: string
  divisions?: string[]
  categories?: string[]
  countries?: string[]
  qrs?: string[],
  gtins?: string[],
  styleColorCodes?: string[],
  styleCodes?: string[]
}

export interface Rule {
  name: string
  priority: number
  redirectUrl: string
  divisions?: string[]
  categories?: string[]
  countries?: string[]
  qrs?: string[],
  gtins?: string[],
  styleColorCodes?: string[],
  styleCodes?: string[]
}

export const getTtl = (expirationDateTime: Date) => Math.floor(expirationDateTime.getTime() / 1e3)

const Register = (props: RegistrationFormProps) => {
  const { 
    rule,
    action,
    onRegistrationComplete,
    author
  } = props

  const [requiredProperties, setRequiredProperties] = useState({
    name: '',
    priority: '1',
    redirectUrl: ''
  })

  const [categories, setCategories] = useState<string[] | undefined>([])
  const [divisions, setDivisions] = useState<string[] | undefined>([])
  const [countries, setCountries] = useState<string[] | undefined>([])
  const [qrs, setQrs] = useState<string[] | undefined>([])
  const [gtins, setGtins] = useState<string[] | undefined>([])
  const [styleColorCodes, setStyleColorCodes] = useState<string[] | undefined>([])
  const [styleCodes, setStyleCodes] = useState<string[] | undefined>([])
  const [ruleExpirationDatetime, setRuleExpiration] = useState<Date>(new Date())

  useEffect(() => {
    if (rule) {
      setRequiredProperties({
        name: rule.name,
        priority: rule.priority,
        redirectUrl: rule.redirectUrl
      })

      setCountries(rule.countries)
      setCategories(rule.categories)
      setDivisions(rule.divisions)
      setQrs(rule.qrs)
      setGtins(rule.gtins)
      setStyleColorCodes(rule.styleColorCodes)
      setStyleCodes(rule.styleCodes)
    }
  }, [rule])

  const submit = async (name: string, action: Action) => {
    const ttl = getTtl(ruleExpirationDatetime)

    const payload = {
      ...requiredProperties,
      priority: Number(requiredProperties.priority),
      countries,
      categories,
      divisions,
      qrs,
      gtins,
      styleColorCodes,
      styleCodes,
      ttl,
      author
    }

    if (action === Action.UPDATE) {
      await updateRule(name, payload)
    } else {
      const response = await createRule(payload)
      const registrationSuccessful = !!response.redirectUrl
      if (onRegistrationComplete) {
        onRegistrationComplete(registrationSuccessful)
        return
      }
      navigate('/manage-experiences')
    }
  }

  const handleSetExpiration = (date: Date) => {
    setRuleExpiration(date)
  }

  const hasRequiredFields = () => {
    const validTtl = getTtl(ruleExpirationDatetime) > 0 
    return !!(
        requiredProperties.name
        && requiredProperties.priority
        && requiredProperties.redirectUrl
        && validTtl
      )
  }

  const canSubmitRule = hasRequiredFields()
  
  const handleSubmit = () => submit(requiredProperties.name, action)

  return (
    <div style={{ display: 'table', width: '100%', paddingTop: '15px' }}>
      <div style={{ display: 'table-cell', paddingRight: '50px', width: '50%' }}>
        <Section title="Basic Information">
          <RestrictedTextInput
            required
            name="name"
            label="Rule Name"
            onChange={(e) =>
              setRequiredProperties({
                ...requiredProperties,
                name: e.target.value,
              })
            }
            value={requiredProperties.name}
            errorMessage={nameValidationMessage}
            validation={validateName}
          />
          <RestrictedTextInput
            required
            name="priority"
            label="Priority"
            onChange={(e) => setRequiredProperties({
              ...requiredProperties,
              priority: e.target.value
            })}
            value={requiredProperties.priority}
            errorMessage={priorityValidationMessage}
            validation={validatePriority}
          />
          {/* Using RestrictedTextInput is overkill for a static text field.
          Please search epic-react for a more appropriate component for this use case
          */}
          <RestrictedTextInput
            required
            name="author"
            label="Author"
            onChange={(e) => e}
            value={author as string}
            errorMessage=''
            validation={noValidationNecessary}
            readOnly={true}
          />
        </Section>
        <Section title={"Redirect URL"}>
          <UrlInput
            onChange={(value: string) => setRequiredProperties({
              ...requiredProperties,
              redirectUrl: value
            })}
            pathParameterOptions={URL_PARAMETER_OPTIONS}
            value={requiredProperties.redirectUrl}
          />
        </Section>
        <Section title='Rule Expiration'>
          <div className='epic-font-brand' style={{ fontSize: '14px'}}>
            Expiration Time and Date
          </div>
          <DateTimePicker onChange={handleSetExpiration} value={ruleExpirationDatetime}/>
        </Section>
        <Section title='Location Conditions'>
          <MultiSelect 
            title={'Countries'}
            handleChange={setCountries}
            values={countries || []}
            options={COUNTRY_OPTIONS}
          />
        </Section>
      </div>
      <div style={{ display: 'table-cell' }}>
        <Section title='Product Conditions'>
          <MultiSelect
            title={'Divisions'}
            handleChange={setDivisions}
            values={divisions || []}
            options={DIVISION_OPTIONS}
          />
          <MultiSelect
            title={'Categories'}
            handleChange={setCategories}
            values={categories || []}
            options={CATEGORY_OPTIONS}
          />
          <MultiInput
            title={'gtins'}
            values={gtins || []}
            handleChange={setGtins}
            validator={validateGtin}
            errorMessage={gtinValidationMessage}
          />
          <MultiInput
            title={'style color codes'}
            values={styleColorCodes || []}
            handleChange={setStyleColorCodes}
            validator={validateStyleColorCode}
            errorMessage={styleColorCodeValidationMessage}
          />
          <MultiInput
            title={'style codes'}
            values={styleCodes || []}
            handleChange={setStyleCodes}
            validator={validateStyleCode}
            errorMessage={styleCodeValidationMessage}
          />
          <MultiInput
            title={'QRs'}
            values={qrs || []}
            handleChange={setQrs}
            validator={validateQR}
            errorMessage={qrValidationMessage}
          />
        </Section>
        <div
          style={{
            paddingLeft: '9%'
          }}
        >
          <RegisterButton
            handleSubmit={handleSubmit}
            type="Rule"
            disabled={!canSubmitRule}
          />
        </div>     
      </div>
    </div>
  )
}

export default Register

const section = css`
  margin: 20px;
`
