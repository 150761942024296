/** @jsxRuntime classic */
/** @jsx jsx */


import PropTypes from 'prop-types'
import { css, jsx } from '@emotion/react'
import { useState } from 'react'
import { navigate } from 'raviger'
import { Button, IconButton } from '@nike/epic-react-ui'
import { Action } from "../types"
import Register from "./Register"
import { mediumText, buttonGroupStyle, themedButton } from '../commonStyles'
import RegistrationComplete from './RegistrationComplete'
interface RegisterPageProps {
  email: string
}

function RegisterPage(props: RegisterPageProps) {
  const { email } = props

  const [registrationComplete, setRegistrationComplete] = useState<boolean>(false)
  const [registrationSuccessful, setRegistrationSuccessful] = useState<boolean>(false)

  const resetRegistration = () => {
    setRegistrationSuccessful(false)
    setRegistrationComplete(false)
  }
  const setRegistrationStatus = (successful: boolean) => {
    setRegistrationSuccessful(successful)
    setRegistrationComplete(true)
  }

  return registrationComplete ? (
    <RegistrationComplete successful={registrationSuccessful} resetRegistration={resetRegistration}/>
  ) : (
    <div>
      <h2 className='epic-font-brand' style={{ margin: 25 }}>
        Register Redirect Rule
      </h2>
      <Register title={'Register Rule'} action={Action.CREATE} onRegistrationComplete={setRegistrationStatus} author={email}/>
    </div>
  )
}

export default RegisterPage
