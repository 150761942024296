import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { usePopper } from 'react-popper'

import Tooltip from '../Tooltip/Tooltip.js'
import styles from './styles.styl'

const TooltipContent = ({ content, link, linkDescription }) => (
  <div className={styles.tooltipContainer}>
    <p className={styles.tooltipContent}>{content}</p>
    {link && (
      <a className={styles.tooltipLink} href={link}>
        {linkDescription}
      </a>
    )}
  </div>
)

const Button = forwardRef(({ label, isActive, onChipClick, onMouseEnter, onMouseLeave }, ref) => (
  <button
    ref={ref}
    type='button'
    className={classnames(styles.button, { [styles.buttonActive]: isActive })}
    onClick={() => onChipClick(label)}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {label}
  </button>
))

export const Chip = ({ label, onChipClick, tooltip, isActive, tooltipProps }) => {
  let mouseLeaveTimeout = null

  const [referenceElement, setReferenceElement] = useState(null)
  const [isHovered, setIsHovered] = useState(false)
  const [isTooltipHovered, setIsTooltipHovered] = useState(false)
  const referenceElementRef = useRef(null)
  const popperElementRef = useRef(null)
  const isTooltipHoveredRef = useRef(isTooltipHovered)

  const { styles, attributes } = usePopper(referenceElement, popperElementRef.current, {
    placement: 'top',
  })

  isTooltipHoveredRef.current = isTooltipHovered

  useEffect(() => {
    return () => {
      clearTimeout(mouseLeaveTimeout)
    }
  }, [])

  const chip = (
    <Button
      label={label}
      ref={referenceElementRef}
      onChipClick={onChipClick}
      isActive={isActive}
      onMouseEnter={() => {
        // pass fresh DOM node to Popper
        setReferenceElement(referenceElementRef.current)
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        mouseLeaveTimeout = setTimeout(() => {
          if (!isTooltipHoveredRef.current) {
            // stop listening to DOM node, there is no need to keep tooltip position updated when it's not visible
            setReferenceElement(null)
            setIsHovered(false)
            setIsTooltipHovered(false)
          }
        }, 250)
      }}
    />
  )

  if (tooltip) {
    return (
      <Tooltip
        component={TooltipContent}
        ref={popperElementRef}
        componentProps={{ ...tooltip }}
        medium
        {...tooltipProps}
        hasPopperSupport={true}
        popperProps={{ style: styles.popper, attributes: attributes.popper }}
        isHovered={isHovered}
        setIsHovered={setIsHovered}
        setIsTooltipHovered={setIsTooltipHovered}
      >
        {chip}
      </Tooltip>
    )
  }

  return chip
}

export const Chips = ({ chips, activeItems, onChipClick }) => (
  <ul className={styles.chipsList}>
    {chips.map((chip) => {
      const isActive = activeItems.includes(chip.label)
      return (
        <li className={styles.chipsListItem} key={chip.label}>
          <Chip
            label={chip.label}
            onChipClick={onChipClick}
            tooltip={chip.tooltip}
            isActive={isActive}
          />
        </li>
      )
    })}
  </ul>
)

Chips.propTypes = {
  /** Array of chips in form: */
  chips: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      tooltip: PropTypes.shape({
        content: PropTypes.string,
        link: PropTypes.string,
        linkDescription: PropTypes.string,
      }),
    })
  ).isRequired,
  /** Function triggered on chip click */
  onChipClick: PropTypes.func,
  /** Array of currently selected chips */
  activeItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Props passed down to Tooltip component, e.g. { position: bottom } */
  tooltipProps: PropTypes.object,
}

Chips.defaultProps = {
  chips: [],
  activeItems: [],
}

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  onChipClick: PropTypes.func,
  tooltip: PropTypes.shape({
    content: PropTypes.string,
    link: PropTypes.string,
    linkDescription: PropTypes.string,
  }),
  isActive: PropTypes.bool,
  tooltipProps: PropTypes.object,
}

TooltipContent.propTypes = {
  content: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkDescription: PropTypes.string,
}

Button.propTypes = {
  onChipClick: PropTypes.func,
  label: PropTypes.string,
  isActive: PropTypes.bool,
}
