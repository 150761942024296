import { Icon } from '@EPIC'
import classnames from 'classnames'
import { isNil } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'
import { useExpanded, useGlobalFilter, useRowSelect, useSortBy, useTable } from 'react-table'

import {
  BulkSelectRow,
  IndeterminateCheckbox,
  TableContainer,
  TableHeader,
  Tbody,
  Td,
} from './CommonTableComponents.js'
import {
  ColumnFilterButton,
  FilterButton,
  FilterSection,
  NoDataRow,
  NoPagination,
  SearchBar,
} from './PagedFilterTableComponents.js'
import styles from './styles.styl'

export const setMaxHeight = (visibleRowsCount, isCompact) => {
  const borderSize = 1.5
  const cellHeight = isCompact ? 16 * 2 + 20 + borderSize : 24 * 2 + 20 + borderSize
  return !isNil(visibleRowsCount) ? { maxHeight: `${visibleRowsCount * cellHeight - 1}px` } : {} // minus one to hide the last row line :
}

const Table = ({
  columns: appColumns,
  data,
  withSearchBar,
  maxLength,
  withColumnFilter,
  filters,
  defaultFilters,
  onFiltersChange,
  searchBarPlaceholder,
  searchBarLabel,
  className,
  visibleRowsCount,
  isCompact,
  withSelectableRows,
  getChildRows,
  onSelectedRowChange,
  BulkSelectRowAside,
  initialState,
  onRowClick,
}) => {
  const columns = useMemo(
    () => (getChildRows ? [makeExpanderColumn(getChildRows), ...appColumns] : appColumns),
    [appColumns]
  )
  const plugins = [useGlobalFilter, useSortBy]
  if (getChildRows) plugins.push(useExpanded)
  const {
    state: { globalFilter, selectedRowIds },
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    selectedFlatRows,
    toggleAllRowsSelected,
    getToggleHideAllColumnsProps,
    allColumns,
  } = useTable(
    {
      columns,
      data,
      initialState,
    },
    ...plugins,
    useRowSelect,
    (hooks) => {
      if (withSelectableRows) {
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
          },
          ...columns,
        ])
      }
    }
  )

  const [showFilters, setShowFilters] = useState(false)
  const [showAllFilters, setShowAllFilters] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState(defaultFilters || {})
  const [filterFilterQueries, setFilterFilterQueries] = useState({})
  const [columnFilterVisible, setColumnFilterVisible] = useState(false)
  const [columnFilterQuery, setColumnFilterQuery] = useState('')

  const setFilters = (newFilterState) => {
    setSelectedFilters(newFilterState)
    onFiltersChange && onFiltersChange(newFilterState)
  }
  const handleChangeSelectedFilters = (filterGroup, event) => {
    if (!selectedFilters[filterGroup]) selectedFilters[filterGroup] = []
    setFilters({
      ...selectedFilters,
      [filterGroup]: event.target.checked
        ? [...selectedFilters[filterGroup], event.target.name]
        : [...selectedFilters[filterGroup]].filter((item) => item !== event.target.name),
    })
  }
  const handleSelectAllFilters = (filterGroup, childFilterArray) => {
    setFilters({
      ...selectedFilters,
      [filterGroup]: childFilterArray.map((item) => item.accessor),
    })
  }
  const handleClearSelectedFilters = (filterGroup) => {
    if (filterGroup) {
      setFilters({
        ...selectedFilters,
        [filterGroup]: [],
      })
    } else {
      setFilters({})
    }
  }
  const handleUpdateFilterFilterQuery = (filterGroup, e) => {
    setFilterFilterQueries({
      ...filterFilterQueries,
      [filterGroup]: e.target.value,
    })
  }

  const searchBarProps = {
    globalFilter,
    setGlobalFilter,
    searchBarPlaceholder,
    searchBarLabel,
    maxLength,
  }

  const headerProps = {
    headerGroups,
    isCompact,
  }

  const bodyProps = {
    rows,
    prepareRow,
    isCompact,
    getChildRows,
  }

  const bulkSelectProps = {
    selectedRowIds,
    selectedFlatRows,
    toggleAllRowsSelected,
    clearSelectedRow: () => toggleAllRowsSelected(false),
  }

  withSelectableRows &&
    onSelectedRowChange &&
    onSelectedRowChange({ selectedRowIds, selectedFlatRows })

  const filterCount = useMemo(
    () => Object.keys(selectedFilters).reduce((acc, curr) => acc + selectedFilters[curr].length, 0),
    [selectedFilters]
  )

  const handleFilterBtnClick = useCallback(() => setShowFilters(!showFilters), [showFilters])

  if (columns.length === 0) return <></>

  return (
    <div className={classnames(className)}>
      {(withSearchBar || filters?.length || withColumnFilter) && (
        <div className={styles.searchFilterSection}>
          {withSearchBar && <SearchBar {...searchBarProps} />}
          {filters?.length > 0 && (
            <FilterButton
              showFilters={showFilters}
              handleFilterBtnClick={handleFilterBtnClick}
              filterCount={filterCount}
            />
          )}
          {withColumnFilter && (
            <ColumnFilterButton
              columns={allColumns}
              toggleAll={getToggleHideAllColumnsProps}
              columnFilterVisible={columnFilterVisible}
              setColumnFilterVisible={setColumnFilterVisible}
              columnFilterQuery={columnFilterQuery}
              setColumnFilterQuery={setColumnFilterQuery}
              withSelectableRows={withSelectableRows}
            />
          )}
        </div>
      )}
      {showFilters && filters?.length > 0 && (
        <FilterSection
          selectedFilters={selectedFilters}
          handleChangeSelectedFilters={handleChangeSelectedFilters}
          handleSelectAllFilters={handleSelectAllFilters}
          handleClearSelectedFilters={handleClearSelectedFilters}
          showAllFilters={showAllFilters}
          setShowAllFilters={setShowAllFilters}
          filters={filters}
          filterFilterQueries={filterFilterQueries}
          handleUpdateFilterFilterQuery={handleUpdateFilterFilterQuery}
        />
      )}
      <div className={`${styles.scrollTable}`}>
        {withSelectableRows && selectedFlatRows?.length > 0 && (
          <BulkSelectRow {...bulkSelectProps}>
            {BulkSelectRowAside && <BulkSelectRowAside {...bulkSelectProps} />}
          </BulkSelectRow>
        )}

        {data?.length === 0 ? (
          <NoDataRow />
        ) : (
          <TableContainer
            className={classnames(
              withSelectableRows && styles.withCheckboxColumn,
              withSelectableRows && selectedFlatRows?.length > 0 && styles.bulkSelectRowActive
            )}
            {...getTableProps()}
          >
            <TableHeader {...headerProps} />
            <Tbody {...getTableBodyProps()} style={setMaxHeight(visibleRowsCount, isCompact)}>
              {data?.length === 0 ? (
                <NoDataRow />
              ) : (
                <NoPagination
                  onRowClick={onRowClick}
                  {...bodyProps}
                  expanderCell={<Td className={styles.expander} />}
                />
              )}
            </Tbody>
          </TableContainer>
        )}
      </div>
    </div>
  )
}

Table.propTypes = {
  /** Allow custom className to the component */
  className: PropTypes.string,
  /** Column headers (`Header` value is the text that will show up in the table & `accessor` value is which value shows up in the cell)*/
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      accessor: PropTypes.string.isRequired,
      defaultCanSort: PropTypes.bool,
      disableSortBy: PropTypes.bool,
      sortDescFirst: PropTypes.bool,
      sortInverted: PropTypes.bool,
      sortType: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    })
  ).isRequired,
  /** Array of data with `accessor` as the keys  */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Allow for table text search */
  withSearchBar: PropTypes.bool,
  /** Max character limit for search control */
  maxLength: PropTypes.number,
  /** Object of filter properties */
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          accessor: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  /** Object that provides the table with the filters to be selected by default, example: `{ filterName: ['valueSelected']  }` */
  defaultFilters: PropTypes.object,
  /** Function called when selected filters change, is passed selected filter state */
  onFiltersChange: PropTypes.func,
  /** Placeholder text to show up in the Table searchBar */
  searchBarPlaceholder: PropTypes.string,
  /** Label text to show up in the Table searchBar */
  searchBarLabel: PropTypes.string,
  /** Less padding around the Header & Body's cells */
  isCompact: PropTypes.bool,
  /** Allow for body table scrolling, number of rows to show */
  visibleRowsCount: PropTypes.number,
  /** Provide a side panel with additional information about a selected row */
  withSelectableRows: PropTypes.bool,
  /** Get the selected */
  onSelectedRowChange: PropTypes.func,
  /** Pass in a Component to be shown in the Bulk Action Row with access to `selectedRowIds`, `selectedFlatRows`, & `clearSelectedRow` parameters */
  BulkSelectRowAside: PropTypes.func,
  /** Allow for column filtering */
  withColumnFilter: PropTypes.bool,
  /**
   * Provide child/sub rows for a given row. Enables row expansion.
   * Function is invoked with
   * * `row`: the row-to-be-expanded
   * * `expanderCell`: a `ReactNode` containing a styled Cell that will properly align with the row-expander column.
   *
   * getChildRows should return an array of `<Tr />` nodes
   * */
  getChildRows: PropTypes.func,
  onRowClick: PropTypes.func,
}

Table.defaultProps = {
  className: '',
  columns: [],
  data: [],
  withSearchBar: false,
  filters: [],
  onFiltersChange: null,
  searchBarPlaceholder: 'Search',
  searchBarLabel: '',
  isCompact: false,

  visibleRowsCount: 10,
  withSelectableRows: false,
  withColumnFilter: false,
}

export default Table

function makeExpanderColumn(getChildRows) {
  return {
    // Make an expander cell
    Header: () => null, // No header
    id: 'expander', // It needs an ID
    className: styles.expander,
    Cell: ({ row }) => {
      if (!getChildRows({ row })?.length) return null
      return (
        // Use Cell to render an expander for each row.
        // We can use the getToggleRowExpandedProps prop-getter
        // to build the expander.
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <Icon type='CaretUp' size='m' /> : <Icon type='CaretDown' size='m' />}
        </span>
      )
    },
  }
}
