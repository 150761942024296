import { Label } from '@EPIC'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './TextInput.styl'

const TextInput = React.forwardRef((props, ref) => {
  const { border, required, hasErrors, errorMessage, className, label, ...others } = props

  let inputClass = styles.input

  if (!border && !hasErrors) {
    inputClass = classnames(inputClass, styles.noBorder)
  }

  if (hasErrors) {
    inputClass = classnames(inputClass, styles.hasErrors)
  }

  return (
    <Label
      label={label}
      className={className}
      required={required}
      hasErrors={hasErrors}
      errorMessage={errorMessage}
    >
      <input ref={ref} required={required} className={inputClass} {...others} />
    </Label>
  )
})

TextInput.propTypes = {
  /** Sets label for element */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  /** Handles input change event */
  onChange: PropTypes.func,
  /** Specifies if border is present */
  border: PropTypes.bool,
  /** Sets className on input element */
  className: PropTypes.string,
  /** Sets input as required and also adds red star to label */
  required: PropTypes.bool,
  /** Sets whether input is in error state */
  hasErrors: PropTypes.bool,
  /** Error message to be displayed to user, appears in top right of input */
  errorMessage: PropTypes.string,
}

TextInput.defaultProps = {
  border: true,
  className: '',
}

export default TextInput
