import { combineReducers } from 'redux'
import { parseErrorMiddleware } from '@nike/redux-action-utils'
import { reducer as auth } from '@nike/oauth-client'
import notifications from '../notifications/reducer'

const rootReducer = combineReducers({
  auth,
  parseErrorMiddleware,
  notifications,
})

export default rootReducer
