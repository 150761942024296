export enum Action {
  NONE = '',
  UPDATE = 'update',
  CREATE = 'create',
  DELETE = 'delete'
}

export interface RedirectRule {
  name: string
  priority: number
  redirectUrl: string
  countries?: string[]
  divisions?: string[]
  categories?: string[]
  qrs?: string[]
  gtins?: string[]
  styleColorCodes?: string[]
}