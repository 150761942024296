import { Icon } from '@EPIC'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import Overlay from '../Overlay/Overlay.js'
import styles from './style.styl'

function AddChildrenWarning() {
  return (
    <p>
      You must add children to this component. Please see documentation{' '}
      <a href='/?path=/story/next-gen-modal--show-modal-with-button'>here</a>
    </p>
  )
}

const Modal = ({ onClose, ...props }) => {
  const [show, setShow] = useState(false)

  const { className, children, closeButton, modalSize } = props
  let sizeClass
  if (modalSize === 'sm' || modalSize === 'md' || modalSize === 'lg') {
    sizeClass = modalSize
  }

  const close = () => {
    setShow(false)
    onClose && onClose()
  }

  const keyDown = (e) => {
    if (e.key === 'Escape') close()
  }

  useEffect(() => {
    window.addEventListener('keydown', keyDown)
    setShow(props.show)
    return () => {
      window.removeEventListener('keydown', keyDown)
    }
  }, [props.show])

  return (
    <Overlay show={show} onClick={close}>
      <div
        className={`${styles.modal} ${sizeClass ? styles[sizeClass] : ''} ${
          show ? styles.show : ''
        } epic-font-base ${className}`}
      >
        {closeButton ? (
          <div className={styles.close}>
            <button onClick={close}>
              <Icon type='Close' size='s' />
            </button>
          </div>
        ) : null}
        <div className={styles.modalInner}>{children || AddChildrenWarning()}</div>
      </div>
    </Overlay>
  )
}

Modal.Title = function ({ children }) {
  return <div className={styles.title}>{children}</div>
}

Modal.Content = function ({ children }) {
  return <div className={styles.content}>{children}</div>
}

Modal.Actions = function ({ children }) {
  return <div className={styles.actions}>{children}</div>
}

Modal.propTypes = {
  /** Dictates whether or not the modal is visible */
  show: PropTypes.bool,
  /** Legacy title prop */
  title: PropTypes.string,
  /** Custom Content for the modal, renders beneath the title */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /** Custom className for the modal */
  className: PropTypes.string,
  /** Specifies presence of `X` close button in top right of modal */
  closeButton: PropTypes.bool,
  /** Function to fire on modal close */
  onClose: PropTypes.func,
  /** Modal size specification (xsm, sm, md, lg, auto) */
  modalSize: PropTypes.string,
}

Modal.defaultProps = {
  show: false,
  className: '',
  modalSize: 'sm',
  closeButton: true,
}

export default Modal
