import PropTypes from 'prop-types'
import React from 'react'

// eslint-disable-next-line import/no-namespace
import CXIcon, { CXTypes } from './CXIcon.js'
import DSIcon, { DSTypes } from './DSIcon.js'

const Icon = ({ type, className, fontSize, ...props }) => {
  if (CXTypes.includes(type)) {
    return <CXIcon type={type} className={className} {...props} />
  }

  if (DSTypes.includes(type)) {
    return <DSIcon type={type} className={className} {...props} />
  }

  return (
    <i className={`epic-icon epic-icon-${type} ${className}`} style={{ fontSize }} {...props} />
  )
}

Icon.propTypes = {
  /** className passed to Icon */
  className: PropTypes.string,
  /** Allow to custom webfont size */
  size: PropTypes.oneOf(['s', 'm', 'l']),
  /** icon type  */
  type: PropTypes.oneOf([
    ...DSTypes,
    ...CXTypes,
    'add',
    'arrow-backward',
    'arrow-down',
    'arrow-forward',
    'arrow-up',
    'bookmark',
    'calendar',
    'checkmark',
    'chevron-back',
    'chevron-circle-back',
    'chevron-circle-forward',
    'chevron-down',
    'chevron-forward',
    'chevron-up',
    'close-x',
    'collaborate',
    'confluence',
    'contact',
    'delete',
    'edit',
    'filter-x',
    'filter',
    'github',
    'information',
    'jira',
    'map-pin',
    'nike-logo',
    'preview-eye',
    'preview',
    'profile',
    'quotes',
    'search',
    'share',
    'slack-with-name',
    'slack',
    'steps',
    'up-and-running',
    'user-profile',
    'walkthrough',
    'warning',
  ]).isRequired,

  /** WILL BE DEPRECATED Allow to custom webfont size */
  fontSize: PropTypes.string, // TODO: Remove this in v1
}

Icon.defaultProps = {
  className: '',
  size: 'm',
  fontSize: '14px',
}

export default Icon
