import RuleList from "./RuleList"

function HomePage() {
  return (
    <div>
      <div className="CreateExperience">
        <RuleList />
      </div>
    </div>
  )
}

export default HomePage
