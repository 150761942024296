import PropTypes from 'prop-types'
import React, { Component } from 'react'

import styles from './Search.styl'

const SearchResults = ({ items, open, onClick, activeIndex, maxSearchItems = 7 }) => {
  if (items) {
    if (items.length < 1) {
      return (
        <ul className={`${styles.searchResults} ${open ? styles.open : ''}`} tabIndex='0'>
          <li className={styles.searchResult}>
            <span>No results.</span>
          </li>
        </ul>
      )
    }
    return (
      <ul className={`${styles.searchResults} ${open ? styles.open : ''}`} tabIndex='0'>
        {items
          .slice(0, maxSearchItems)
          .map((item) => (typeof item === 'string' ? { label: item, value: item } : item))
          .map((item, i) => (
            <li
              key={`${item.value}-${i}`}
              className={`${styles.searchResult} ${activeIndex === i ? styles.active : ''}`}
            >
              <span data-value={item.value} onClick={onClick}>
                {item.label}
              </span>
            </li>
          ))}
      </ul>
    )
  }
}

SearchResults.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
      })
    ),
  ]),
  maxSearchItems: PropTypes.number,
}

class Search extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.previewItems && nextProps.previewItems.length !== prevState.previewItemsCount) {
      return {
        cursor: -1,
        previewItemsCount: nextProps.previewItems ? nextProps.previewItems.length : 0,
      }
    }

    return null
  }

  state = {
    cursor: -1,
    previewItemsCount: this.props.previewItems ? this.props.previewItems.length : 0,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    document.addEventListener('keydown', this.handleKeypress)
    if (this.xRef) this.xRef.addEventListener('mouseover', this.handleMouseOver)
    this.search && this.search.focus()
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    document.removeEventListener('keydown', this.handleKeypress)
    if (this.xRef) this.xRef.removeEventListener('mouseover', this.handleMouseOver)
  }

  // Reset selected value from keypress
  handleMouseOver = () => {
    this.setState({ cursor: -1 })
  }

  handleClickOutside = (event) => {
    if (
      this.xRef &&
      !this.xRef.contains(event.target) &&
      event.target.id !== 'er-nav-search-submit'
    ) {
      this.setState({ cursor: -1 }, () => {
        this.props.onClose()
      })
    }
  }

  handleKeypress = (e) => {
    let { cursor } = this.state
    if (e.keyCode === 27) {
      return this.props.onClose()
    }
    if (e.keyCode === 13 && this.props.value.length > 0) {
      if (cursor === -1) {
        this.props.onSubmit()
        return this.props.onClose()
      }
      this.props.onSubmit(this.props.previewItems[cursor])
      return this.props.onClose()
    }
    if (e.keyCode === 38) this.navUp()
    if (e.keyCode === 40) this.navDown()
  }

  navUp() {
    let { cursor } = this.state
    if (cursor === -1 || cursor === 0) {
      this.setState({ cursor: this.props.previewItems.length - 1 })
    } else {
      this.setState({ cursor: --cursor })
    }
  }

  navDown() {
    let { cursor } = this.state
    if (cursor === -1 || cursor >= this.props.previewItems.length - 1) {
      this.setState({ cursor: 0 })
    } else this.setState({ cursor: ++cursor })
  }

  onResultClick(value) {
    this.setState({ cursor: -1 }, () => {
      this.props.onSubmit(value)
      this.props.onClose()
    })
  }

  render() {
    const { placeholder, onChange, previewItems, value, showButtonFirst } = this.props

    return (
      <div
        role='search'
        className={`${styles.search} ${!showButtonFirst ? styles.searchLeft : styles.searchRight}`}
        ref={(n) => {
          this.xRef = n
        }}
      >
        <input
          className={styles.searchInput}
          value={this.props.value}
          ref={(n) => {
            this.search = n
          }}
          placeholder={placeholder}
          onChange={onChange}
          aria-label='Search through provided list object'
        />
        {previewItems && (
          <SearchResults
            items={previewItems}
            activeIndex={this.state.cursor}
            open={value.length > 0}
            onClick={(e) => this.onResultClick(e.currentTarget.dataset.value)}
          />
        )}
      </div>
    )
  }
}

Search.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  placeholder: PropTypes.string,
  previewItems: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
      })
    ),
  ]),
}

export default Search
