import React, { ChangeEvent } from "react"
import { NextGen } from "@nike/epic-react-ui"
import Spacer from "../components/Spacer"

type RestrictedTextInputProps = {
  validation: (value: string) => any,
  errorMessage: string,
  required: boolean,
  name: string,
  label: string,
  value: string,
  type?: string,
  placeholder?: string,
  helperText?: string,
  onChange: (event: ChangeEvent<HTMLInputElement>) => void,
  readOnly?: boolean
};

const RestrictedTextInput = (props: RestrictedTextInputProps) => {
  const {
    name,
    value,
    validation,
    errorMessage,
    required,
    label,
    placeholder,
    helperText,
    onChange,
    readOnly,
  } = props
  
  return (
    <div>
      <NextGen.TextInput
        name={name}
        value={value}
        hasErrors={value && !validation(value)}
        errorMessage={errorMessage}
        required={required}
        label={label}
        aria-label={label}
        onChange={onChange}
        placeholder={placeholder || ""}
        helperText={helperText || ""}
        disabled={readOnly}
      />
      <Spacer height={15} />
    </div>
  )
}

export default RestrictedTextInput
