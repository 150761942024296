import { Chip, Chips } from './Chips/Chips.js'
import FileInput from './FileInput/FileInput.js'
import ListItem from './ListItem/ListItem.js'
import Modal from './Modal/Modal.js'
import NavBar from './NavBar/NavBar.js'
import Select from './Select/Select.js'
import TextArea from './TextArea/TextArea.js'
// Next Generation
import TextInput from './TextInput/TextInput.js'
import Tooltip from './Tooltip/Tooltip.js'

export default {
  TextInput,
  TextArea,
  FileInput,
  Modal,
  Select,
  NavBar,
  ListItem,
  Tooltip,
  Chips,
  Chip,
}
