import classnames from 'classnames'
import { isNil } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'

import {
  BulkSelectRow,
  IndeterminateCheckbox,
  TableContainer,
  TableHeader,
  Tbody,
} from './CommonTableComponents.js'
import {
  ColumnFilterButton,
  FilterButton,
  FilterSection,
  NoDataRow,
  Pagination,
  PaginationControls,
  SearchBar,
} from './PagedFilterTableComponents.js'
import styles from './styles.styl'
import { setMaxHeight } from './Table.js'

function PaginationTable({
  columns,
  data,
  withSearchBar,
  maxLength,
  withColumnFilter,
  searchBarPlaceholder,
  searchBarLabel,
  pageSizeOptions,
  className,
  isCompact,
  initialState,
  defaultFilters,
  filters,
  onFiltersChange,
  withSelectableRows,
  onSelectedRowChange,
  BulkSelectRowAside,
  emptyTitle,
  emptySubtitle,
  getRowId,
  autoResetSelectedRows = true,
  visibleRowsCount,
}) {
  const {
    state: { globalFilter, pageIndex, pageSize, selectedRowIds },
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount, // # total pages available
    setPageSize, // Update number of visible rows

    setGlobalFilter,

    selectedFlatRows,
    toggleAllRowsSelected,
    getToggleHideAllColumnsProps,
    allColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: pageSizeOptions.length > 0 ? pageSizeOptions[0] : 10, // Default to `pageSizeOptions` first item
        ...initialState,
      },
      autoResetSelectedRows,
      getRowId,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,

    useRowSelect,
    (hooks) => {
      if (withSelectableRows) {
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            ),
            Cell: ({ row }) => {
              return <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            },
          },
          ...columns,
        ])
      }
    }
  )

  if (columns.length === 0) return null

  const [showFilters, setShowFilters] = useState(false)
  const [showAllFilters, setShowAllFilters] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState(defaultFilters || {})
  const [filterFilterQueries, setFilterFilterQueries] = useState({})
  const [columnFilterVisible, setColumnFilterVisible] = useState(false)
  const [columnFilterQuery, setColumnFilterQuery] = useState('')

  const setFilters = (newFilterState) => {
    setSelectedFilters(newFilterState)
    onFiltersChange(newFilterState)
  }
  const handleChangeSelectedFilters = (filterGroup, event) => {
    if (!selectedFilters[filterGroup]) selectedFilters[filterGroup] = []
    setFilters({
      ...selectedFilters,
      [filterGroup]: event.target.checked
        ? [...selectedFilters[filterGroup], event.target.name]
        : [...selectedFilters[filterGroup]].filter((item) => item !== event.target.name),
    })
  }
  const handleSelectAllFilters = (filterGroup, childFilterArray) => {
    setFilters({
      ...selectedFilters,
      [filterGroup]: childFilterArray.map((item) => item.accessor),
    })
  }
  const handleClearSelectedFilters = (filterGroup) => {
    if (filterGroup) {
      setFilters({
        ...selectedFilters,
        [filterGroup]: [],
      })
    } else {
      setFilters({})
    }
  }
  const handleUpdateFilterFilterQuery = (filterGroup, e) => {
    setFilterFilterQueries({
      ...filterFilterQueries,
      [filterGroup]: e.target.value,
    })
  }

  const paginationProps = {
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageIndex,
    pageOptions,
    totalRowCount: rows.length,
    gotoPage,
    pageCount,
    pageSize,
    setPageSize,
    pageSizeOptions,
    pageNumbersOptions: [...Array(pageCount)].map((_, num) => ({
      value: num.toString(),
      label: `${num + 1}`.toString(),
    })),
  }

  const searchBarProps = {
    className: styles.SearchBar,
    globalFilter,
    setGlobalFilter,
    searchBarPlaceholder,
    searchBarLabel,
    ariaLabel: 'GlobalTableFilter',
    maxLength,
  }

  const headerProps = {
    headerGroups,
    isCompact,
  }

  const bodyProps = {
    page,
    prepareRow,
    isCompact,
  }

  const bulkSelectProps = {
    selectedRowIds,
    selectedFlatRows,
    toggleAllRowsSelected,
    clearSelectedRow: () => toggleAllRowsSelected(false),
  }

  useEffect(() => {
    withSelectableRows &&
      onSelectedRowChange &&
      onSelectedRowChange({ selectedRowIds, selectedFlatRows })
  }, [selectedFlatRows.length])

  const filterCount = useMemo(
    () => Object.keys(selectedFilters).reduce((acc, curr) => acc + selectedFilters[curr].length, 0),
    [selectedFilters]
  )

  const handleFilterBtnClick = useCallback(() => setShowFilters(!showFilters), [showFilters])

  return (
    <div {...className}>
      <div className={styles.searchFilterSection}>
        {withSearchBar && <SearchBar {...searchBarProps} />}
        {!!filters.length && (
          <FilterButton
            showFilters={showFilters}
            handleFilterBtnClick={handleFilterBtnClick}
            filterCount={filterCount}
          />
        )}
        {withColumnFilter && (
          <ColumnFilterButton
            columns={allColumns}
            toggleAll={getToggleHideAllColumnsProps}
            columnFilterVisible={columnFilterVisible}
            setColumnFilterVisible={setColumnFilterVisible}
            columnFilterQuery={columnFilterQuery}
            setColumnFilterQuery={setColumnFilterQuery}
            withSelectableRows={withSelectableRows}
          />
        )}
      </div>
      {showFilters ? (
        <FilterSection
          selectedFilters={selectedFilters}
          handleChangeSelectedFilters={handleChangeSelectedFilters}
          handleSelectAllFilters={handleSelectAllFilters}
          handleClearSelectedFilters={handleClearSelectedFilters}
          showAllFilters={showAllFilters}
          setShowAllFilters={setShowAllFilters}
          filters={filters}
          filterFilterQueries={filterFilterQueries}
          handleUpdateFilterFilterQuery={handleUpdateFilterFilterQuery}
        />
      ) : null}

      {selectedFlatRows.length > 0 && (
        <BulkSelectRow {...bulkSelectProps}>
          {BulkSelectRowAside && <BulkSelectRowAside {...bulkSelectProps} />}
        </BulkSelectRow>
      )}

      {data.length === 0 ? (
        <NoDataRow title={emptyTitle} subtitle={emptySubtitle} />
      ) : (
        <TableContainer
          className={classnames({
            [styles.scrollTable]: !isNil(visibleRowsCount) && visibleRowsCount > 0,
            [styles.withCheckboxColumn]: withSelectableRows,
            [styles.bulkSelectRowActive]: withSelectableRows && selectedFlatRows.length > 0,
          })}
          {...getTableProps()}
        >
          <>
            <TableHeader {...headerProps} />
            <Tbody {...getTableBodyProps()} style={setMaxHeight(visibleRowsCount, isCompact)}>
              <Pagination {...bodyProps} />
            </Tbody>
          </>
        </TableContainer>
      )}

      {data.length !== 0 && <PaginationControls {...paginationProps} />}
    </div>
  )
}

PaginationTable.propTypes = {
  /** Allow custom className to the component */
  className: PropTypes.string,
  /** Column headers (`Header` value is the text that will show up in the table & `accessor` value is which value shows up in the cell)*/
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      accessor: PropTypes.string.isRequired,
      defaultCanSort: PropTypes.bool,
      disableSortBy: PropTypes.bool,
      sortDescFirst: PropTypes.bool,
      sortInverted: PropTypes.bool,
      sortType: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    })
  ).isRequired,
  /** Array of data with `accessor` as the keys  */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Allow for table text search */
  withSearchBar: PropTypes.bool,
  /** Max character limit for search control */
  maxLength: PropTypes.number,
  /** Placeholder text to show up in the Table searchBar */
  searchBarPlaceholder: PropTypes.string,
  /** Label text to show up in the Table searchBar */
  searchBarLabel: PropTypes.string,
  /** Less padding around the Header & Body's cells */
  isCompact: PropTypes.bool,
  /** Set the initial state of the table */
  initialState: PropTypes.object,
  /** Object that provides the table with the filters to be selected by default, example: `{ filterName: ['valueSelected']  }` */
  defaultFilters: PropTypes.object,

  /** (PaginationTable & PaginationControlledTable) Set the number of options to list view amount of rows in the table */
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  /** Show rows with a selectable checkbox that when an item is clicked it will show a bulk select row above the table */
  withSelectableRows: PropTypes.bool,
  /** Get the selected */
  onSelectedRowChange: PropTypes.func,
  /** Pass in a Component to be shown in the Bulk Action Row with access to `selectedRowIds`, `selectedFlatRows`, & `clearSelectedRow` parameters */
  BulkSelectRowAside: PropTypes.func,
  /** Object of filter properties */
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          accessor: PropTypes.string.isRequired,
        })
      ),
    })
  ),

  /** Function called when selected filters change, is passed selected filter state */
  onFiltersChange: PropTypes.func,
  /** Allow for column filtering */
  withColumnFilter: PropTypes.bool,
  /** Set the Title for the table's empty/no-data state */
  emptyTitle: PropTypes.string,
  /** Set the Subtitle for the table's empty/no-data state */
  emptySubtitle: PropTypes.string,
  /** Allow for body table scrolling, number of rows to show */
  visibleRowsCount: PropTypes.number,
}

PaginationTable.defaultProps = {
  className: '',
  columns: [],
  data: [],
  withSearchBar: false,
  searchBarPlaceholder: 'Search',
  searchBarLabel: '',
  isCompact: false,
  initialState: {},
  filters: [],
  onFiltersChange: null,

  pageSizeOptions: [10, 20, 25],
  withSelectableRows: false,
  withColumnFilter: false,
}
export default PaginationTable
