import React, { useState, useEffect } from 'react'
import { Select, Button, Section } from '@nike/epic-react-ui'
import RestrictedTextInput from './RestrictedTextInput'
import {
  nameValidationMessage,
  urlValidationMessage,
  validateName,
  validateUrl
} from '../validation'
import { themedButton } from '../commonStyles'

interface SelectOption {
  value: string
  label: string
}

// move to types

interface UrlInputProps {
  pathParameterOptions: SelectOption[]
  onChange: (value: string) => void
  value: string
}

const UrlInput = (props: UrlInputProps) => {
  const [selectedParam, setSelectedParam] = useState<string>('')

  const handleChange = (e) => {
    props.onChange(e.target.value)
  }

  const handleSelect = (e) => {
    setSelectedParam(e.value)
  }

  const addUrlParam = () => {
    const newUrl = `${props.value}/{${selectedParam}}`
    props.onChange(newUrl)
  }

  return (
    <div>
      <RestrictedTextInput
        required
        name="url"
        label="Redirect URL"
        onChange={handleChange}
        value={props.value}
        errorMessage={urlValidationMessage}
        validation={validateUrl}
      />
      <Select 
        value={props.pathParameterOptions.find(item => item.value === selectedParam) || ''}
        options={props.pathParameterOptions}
        label='URL Parameters'
        aria-label='URL Parameters'
        onChange={handleSelect}
      />
      <Button
        small
        onClick={addUrlParam}
        theme={themedButton}
      >
        Append URL Param
      </Button>
    </div>
  )
}

export default UrlInput
