import { Icon } from '@EPIC'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import styles from './styles.styl'

const Favorites = (props) => {
  const [selected, setSelected] = useState(false)
  const onClick = () => {
    props.onClick && props.onClick(!selected)
    props.isInteractive && setSelected(!selected)
  }

  return (
    <div onClick={onClick} className={classnames(styles.epFavorites)}>
      <Icon
        type={selected ? 'FavoriteFilled' : 'Favorite'}
        size={props.size}
        className={classnames(styles.favoritesIcon)}
      />
    </div>
  )
}

Favorites.propTypes = {
  /** onClick for favorites*/
  onClick: PropTypes.func,
  /** Size of the icon (leverages Epic icons: s, m, l) */
  size: PropTypes.string,
  /** Disables selected state of component, onClick will still be called */
  isInteractive: PropTypes.bool,
}

Favorites.defaultProps = {
  size: 's',
  isInteractive: true,
}

export default Favorites
