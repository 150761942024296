/** @jsxRuntime classic */
/** @jsx jsx */
import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { css, jsx } from "@emotion/react"
import {
  PaginationTable,
  Tr,
  Td,
  Thead,
  Th,
  Tbody,
  TableContainer,
  Button,
} from "@nike/epic-react-ui"
import { deleteRule, listRules } from "../util/apiUtil"
import { themedButton } from "../commonStyles"
import InfoIcon from "@material-ui/icons/Info"
import DeleteIcon from "@material-ui/icons/Delete"
import UpdateIcon from "@material-ui/icons/Update"
import RuleModal from "./RuleModal"
import Register, { RuleFormValues } from "../register/Register"
import { Action } from '../types'

const ActionContent = ({ op, name }) => {
  const rules = useRules()
  const rule =
    rules &&
    rules
      ?.filter((item) => item.name === name)
      ?.map(({ name, priority, redirectUrl }) => ({
        name,
        priority,
        redirectUrl,
      }))
  switch (op) {
    case "view":
      return (
        <div>
          <ViewRules devices={rules} name={name} />
        </div>
      )
    case "update":
      return (
        <div>
          <Register action={Action.UPDATE} title="" rule={rule?.[0]} />
        </div>
      )
    case "delete":
      return (
        <div
          style={{ margin: "30px" }}
        >{`Are you sure you would like to delete ${name}`}</div>
      )
  }
  return null
}
const ViewRules = ({ devices, name }) => (
  <div>
    <TableContainer>
      <Thead>
        <Tr>
          {[
            "name",
            "priority",
            "author",
            "categories",
            "countries",
            "divisions",
            "qrs",
          ].map((item, i) => (
            <Th key={i}>{item}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {devices &&
          devices
            .filter((device) => device.name === name)
            .map(({ id, name, priority, author, redirectUrl }) => (
              <Tr key={id}>
                <Td>{name}</Td>
                <Td>{priority}</Td>
                <Td>{author}</Td>
                <Td>{redirectUrl}</Td>
              </Tr>
            ))}
      </Tbody>
    </TableContainer>
  </div>
)
const ActionList = (name) => {
  const containerStyle = { display: "flex", justifyContent: "space-between" }
  const [showModal, setModal] = useState(false)
  const [modalTitle, setModalTitle] = useState("")
  const [op, setOp] = useState("")
  const [button, setButton] = useState("")
  const action = async (op, rule, setModal) => {
    switch (op) {
      case "view":
        setModal(false)
        return
      case "update":
        return
      case "delete":
        await deleteRule(rule)
        setModal(false)
        return
    }
  }
  return (
    <div style={containerStyle}>
      <RuleModal
        show={showModal}
        setModal={setModal}
        title={modalTitle}
        button={button}
        action={() => action(op, name.name, setModal)}
      >
        <ActionContent op={op} name={name.name} />
      </RuleModal>
      <Button
        theme={themedButton}
        small
        onClick={() => {
          setModal(true)
          setModalTitle("View Rule")
          setButton("")
          setOp("view")
        }}
        aria-label="View Rule"
      >
        <InfoIcon />
      </Button>
      <Button
        theme={themedButton}
        small
        onClick={() => {
          setModal(true)
          setModalTitle("Update")
          setButton("")
          setOp("update")
        }}
        aria-label="Update Rule"
      >
        <UpdateIcon />
      </Button>
      <Button
        theme={themedButton}
        small
        onClick={() => {
          setModal(true)
          setModalTitle("Delete")
          setButton("Delete Rule")
          setOp("delete")
        }}
        aria-label="Delete Rule"
      >
        <DeleteIcon />
      </Button>
    </div>
  )
}
const useRules = (): Array<RuleFormValues> => {
  const [rules, setRules] = useState([])
  useEffect(() => {
    listRules().then(
      (data) =>
        data &&
        data.length > 0 &&
        setRules(
          data.map(({ name, priority, author, redirectUrl, qrs }) => ({
            name,
            priority,
            author,
            redirectUrl,
            qrs: qrs && qrs.join(","),
            actions: <ActionList name={name} />,
          }))
        )
    )
  }, [])
  return rules
}
const RulesList = () => {
  const rules = useRules()
  return (
    <PaginationTable
      columns={[
        {
          Header: "Name",
          accessor: "name",
        },
        {
          Header: "Priority",
          accessor: "priority",
        },
        {
          Header: "Author",
          accessor: "author"
        },
        {
          Header: "Redirect URL",
          accessor: "redirectUrl",
          disableGlobalFilter: true,
          disableSortBy: true,
        },
        {
          Header: "QRS",
          accessor: "qrs",
          disableGlobalFilter: true,
          disableSortBy: true,
        },
        {
          Header: "Actions",
          accessor: "actions",
          disableGlobalFilter: true,
          disableSortBy: true,
        },
      ]}
      isCompact
      data={rules}
      withSearchBar
    />
  )
}

ActionContent.defaultProps = {
  op: "",
  name: "",
}

ActionList.defaultProps = {
  name: "",
}

ActionContent.propTypes = {
  op: PropTypes.string,
  name: PropTypes.string,
}
ViewRules.propTypes = {
  devices: PropTypes.object,
  name: PropTypes.string,
}
export default RulesList
