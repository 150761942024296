import { Checkbox, Icon } from '@EPIC'
import classnames from 'classnames'
import React from 'react'

import { NoDataRow, NoPagination } from './PagedFilterTableComponents.js'
import styles from './styles.styl'

export function TableContainer({ className, ...props }) {
  return <table {...props} className={`${styles.table} ${className || ''}`} />
}

export function Thead({ className, ...props }) {
  return <thead {...props} className={`${styles.thead} ${className || ''}`} />
}

export function Th({ isSorted, isSortedDesc, className, ...props }) {
  const mergedClassname = `${styles.th} ${isSorted ? (isSortedDesc ? styles.sortDesc : styles.sortAsc) : ''
    } ${className || ''}`
  return <th role='columnheader' {...props} className={mergedClassname} />
}

export function Tbody({ className, ...props }) {
  return <tbody {...props} className={`${styles.tbody} ${className || ''}`} />
}

export function Tr({ className, onClick, rowRecord, ...props }) {
  let cursorStyle = onClick ? { cursor: 'pointer' } : {}
  return (
    <tr
      role='row'
      style={cursorStyle}
      className={classnames(styles.tr, styles.rowClick, className)}
      onClick={() => onClick?.(rowRecord)}
      {...props}
    />
  )
}

export function Td({ className, ...props }) {
  return <td role='cell' {...props} className={`${styles.td} ${className || ''}`} />
}

export const chevronColor = (isActive) => {
  return isActive ? styles.colorActive : styles.colorDisabled
}

const SortAscending = <Icon type='SortAscending' size='s' className={styles.sortIcon} />

const SortDescending = <Icon type='SortDescending' size='s' className={styles.sortIcon} />

export function createHeaderCell(column) {
  const { render, isSorted, isSortedDesc, canSort } = column

  // Don't show chevrons if sorting is disabled
  if (!canSort) return render('Header')

  return (
    <>
      {isSorted ? (isSortedDesc ? SortDescending : SortAscending) : ''}
      {render('Header')}
    </>
  )
}

export function TableHeader({ headerGroups, isCompact }) {
  return (
    <Thead>
      {headerGroups.map((headerGroup, rowIx) => (
        <Tr key={`head-row-${rowIx}`} {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column, cellIx) => (
            <Th
              {...column.getHeaderProps(column.getSortByToggleProps())}
              isSorted={column.isSorted}
              isSortedDesc={column.isSortedDesc}
              key={`head-cell-${cellIx}`}
              className={classnames({
                [styles.canSort]: column.canSort,
                [styles.compactCell]: isCompact,
                [column.className]: column.className,
              })}
            >
              {createHeaderCell(column)}
            </Th>
          ))}
        </Tr>
      ))}
    </Thead>
  )
}

export function TableBody({ getTableBodyProps, data, rows, prepareRow }) {
  return (
    <Tbody {...getTableBodyProps()}>
      {data.length === 0 ? <NoDataRow /> : <NoPagination rows={rows} prepareRow={prepareRow} />}
    </Tbody>
  )
}

export function BulkSelectRow({ children, selectedFlatRows, toggleAllRowsSelected }) {
  return (
    <div className={styles.bulkSelect}>
      <div className={styles.selected}>
        <Icon type='Close' onClick={() => toggleAllRowsSelected(false)} />
        {selectedFlatRows.length} Selected
      </div>
      <div className={styles.additionalSection}>{children}</div>
    </div>
  )
}

export const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef()
  const resolvedRef = ref || defaultRef

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return <Checkbox type='checkbox' ref={resolvedRef} {...rest} />
})

export const IndeterminateCheckboxAlt = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef()
  const resolvedRef = ref || defaultRef

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return <input type='checkbox' ref={resolvedRef} {...rest} />
})
