/* eslint react/prop-types: 0 */
const Generic = ({ error = any }) => {
  return (
    <div>
      <h1>An error occurred, unable to load page.</h1>
      {error ? <pre>{error.message || error}</pre> : null}
    </div>
  )
}

export default Generic
