import React, { Component } from 'react'
import { Select, Button } from "@nike/epic-react-ui"
import { deleteButton, themedButton } from '../commonStyles'

interface SelectOption {
  value: string
  label: string
}
interface MultiSelectProps {
  title: string
  options: SelectOption[]
  values: any[]
  handleChange: (values: any[]) => void
}

const MultiSelect = (props: MultiSelectProps) => {
  const { 
    handleChange,
    title,
    values,
    options
  } = props

  const handleSelect = (e) => {
    if (values.includes(e.value)) {
      return
    }

    const newValues = [
      ...values,
      e.value
    ]
    handleChange(newValues)
  }

  const handleRemove = (name: string) => {
    const newValues = values.filter(value => value !== name)
    handleChange(newValues)
  }

  return (
    <div style={{ marginBottom: 15 }}>
      <Select
        onChange={handleSelect}
        options={options}
        label={title}
      />     
      {values.map(value => (
        <Button
          small
          key={value}
          onClick={() => handleRemove(value)}
          theme={deleteButton}
        >
          {value} x
        </Button>
      ))}
    </div>
  )
}

export default MultiSelect
