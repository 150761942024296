import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { forwardRef, useMemo } from 'react'
import ReactDOM from 'react-dom'

import styles from './styles.styl'

// NOTE: no props are actually required.
const Tooltip = forwardRef(
  (
    {
      className = '',
      message,
      text,
      position = 'top',
      size = 'default',
      inverse = false,
      small = false,
      large = false,
      medium = false,
      children,
      component = '',
      componentProps,
      tooltipProps,
      hasPopperSupport = false,
      portalParentNode,
      popperProps,
      isHovered,
      setIsHovered,
      setIsTooltipHovered,
    },
    ref
  ) => {
    const tooltipSize = small ? 'small' : large ? 'large' : medium ? 'medium' : size
    const containerClassNames = `${styles.tooltip}`
    const tooltipClassNames = classNames(
      styles.tooltiptext,
      styles[position],
      styles[tooltipSize],
      { [styles.inverse]: inverse, [styles['portal-tooltip-hover']]: hasPopperSupport && isHovered }
    )

    // NOTE: ON THE CONTROVERSIAL USE OF createElement & forwardRef:
    // Yes, we could use an inline function: Custom = props => <component {...props} />
    // but this results in *unexpected unmounting* as a new component is created each time
    // React renders. Component will be unmounted every time the Tooltip is rendered, thus
    // unnecessarily updating the DOM and applied animations or styles will not work properly.
    // IDEA: The code below ensures that what is passed, even if an inline function, will always
    // be a reference to the *same* component.
    const CustomComponent = useMemo(
      () =>
        component && typeof component !== 'object' ? (
          React.forwardRef((componentProps, ref) =>
            React.createElement(component, { ...componentProps, ref })
          )
        ) : (
          <>{React.isValidElement(component) && component}</>
        ),
      [component, componentProps]
    )

    const renderTooltip = () => (
      <span
        aria-label={text || message || 'Tooltip'}
        className={tooltipClassNames}
        data-testid='tooltipMessage'
        ref={ref}
        onMouseEnter={() => {
          if (setIsTooltipHovered) {
            setIsTooltipHovered(true)
          }
        }}
        onMouseLeave={() => {
          if (setIsTooltipHovered && setIsHovered) {
            setIsTooltipHovered(false)
            setIsHovered(false)
          }
        }}
        {...(hasPopperSupport ? { style: popperProps.style, ...popperProps.attributes } : {})}
      >
        {component && typeof component !== 'object' ? (
          <div className={styles['tooltip-content']}>
            <CustomComponent {...componentProps} />
          </div>
        ) : (
          `${text || message || 'Tooltip'}`
        )}
      </span>
    )

    return (
      <div
        className={`${containerClassNames} ${className}`}
        {...tooltipProps}
        data-testid='tooltipContainer'
      >
        {children}
        {hasPopperSupport
          ? ReactDOM.createPortal(renderTooltip(), portalParentNode || document.body)
          : renderTooltip()}
      </div>
    )
  }
)

Tooltip.propTypes = {
  /** Custom className applied to container component (wraps `children` and the tooltip itself) */
  className: PropTypes.string,

  /** Properties to pass to the *tooltip container component* to override the default styles. This property has no effect on the reference element. */
  tooltipProps: PropTypes.object,

  /** Reference element(s) to trigger the wrapped Tooltip */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),

  /** Text string to be displayed in the tooltip popup. Short & sweet!  */
  text: PropTypes.string,

  /** Message (string) is a legacy fallback for `text`, soon to be deprecated */
  message: PropTypes.string,

  /** Component: class component, functional component, render function, or elementType tag string (e.g. 'div') to be rendered as the content of the tooltip popup */
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType, PropTypes.string]),

  /** componentProps: prop object passed to `component`. Include a `ref` to reference that which is rendered by `component` */
  componentProps: PropTypes.object,

  /** Tooltip position relative to reference element */
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),

  /** Tooltip size */
  size: PropTypes.oneOf(['small', 'default', 'large']),

  /** Override `size` prop with 'small' (also overrides `large` prop). NOTE: the `small` is given highest priority since an accidentally 'small' tooltip is less visually disruptive than a 'large' tooltip gone awry.  */
  small: PropTypes.bool,

  /** Override `size` prop with 'large' */
  large: PropTypes.bool,

  /** Inverse colors (white background, black text and border) */
  inverse: PropTypes.bool,

  /** Notify component if should rely on Popper and portal  */
  hasPopperSupport: PropTypes.bool,

  /** Pass custom DOM Node where portal should be mounted */
  portalParentNode: PropTypes.instanceOf(HTMLElement),

  /**  Properties passed down to tooltip DOM element extracted from `usePopper` hook */
  popperProps: PropTypes.shape({
    attributes: PropTypes.shape(PropTypes.objectOf(PropTypes.string)),
    style: PropTypes.shape({ styles: PropTypes.shape(PropTypes.objectOf(PropTypes.string)) }),
  }),

  /** When tooltip is mounted as Portal, hover styles react to `isHovered` changes  */
  isHovered: PropTypes.bool,

  /** When tooltip is mounted as Portal, on mouse leave we should trigger state change in parent component  */
  setIsHovered: PropTypes.func,

  /** When tooltip is mounted as Portal, on mouse enter/leave we should trigger state change in parent component to hold the hover mode while mouse is over tooltip area */
  setIsTooltipHovered: PropTypes.func,
}

Tooltip.defaultProps = {
  className: '',
  message: '',
  position: 'top',
  size: 'default',
  inverse: false,
  tooltipProps: {},
}

export default Tooltip
