import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import FormBaseCtrl from '../FormBase/FormBase.js'
import styles from './style.styl'

const TextArea = React.forwardRef((props, ref) => {
  let classList = classnames(styles.textArea, props.className)
  if (props.disableResize) classList = classnames(classList, styles.disableResize)
  const baseProps = { ...props, disableResize: undefined }
  delete baseProps.disableResize

  return (
    <FormBaseCtrl {...baseProps} ref={ref} className={classList}>
      <textarea type='text' value='' />
    </FormBaseCtrl>
  )
})

TextArea.propTypes = {
  /** Sets label for element */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  /** Sets placeholder for input element */
  placeholder: PropTypes.string,
  /** Text to help user understand what input is for */
  helperText: PropTypes.string,
  /** Max Length */
  maxLength: PropTypes.number,
  /** Handles input change event */
  onChange: PropTypes.func,
  /** Sets className on input element */
  className: PropTypes.string,
  /** Sets input as required and also adds red star to label */
  required: PropTypes.bool,
  /** Disable the control */
  disabled: PropTypes.bool,
  /** Disable resize of textarea */
  disableResize: PropTypes.bool,
  /** Sets whether input is in error state */
  hasErrors: PropTypes.bool,
  /** Error message to be displayed to user. If errorMessage is supplied, helperText will not be displayed */
  errorMessage: PropTypes.string,
}

TextArea.defaultProps = {
  className: '',
  placeholder: '',
  disabled: false,
}

export default TextArea
