import html2canvas from 'html2canvas'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './Screenshot.styl'

class ScreenShot extends React.Component {
  constructor(props) {
    super(props)

    this.min = 10
    this.leftOrigin = 0
    this.topOrigin = 0
    this.state = {
      rect: {},
      showBorder: false,
      showLoader: false,
      finished: false,
    }
  }

  componentDidMount() {
    this.screenshot.addEventListener('mousedown', this.beginSelection)
  }

  componentWillUnmount() {
    this.screenshot.removeEventListener('mousedown', this.beginSelection)
    this.removeSelectionEventHandlers()
  }

  beginSelection = (e) => {
    this.leftOrigin = e.pageX - window.pageXOffset
    this.topOrigin = e.pageY - window.pageYOffset

    this.addSelectionEventHandlers()
  }

  addSelectionEventHandlers = () => {
    this.screenshot.addEventListener('mousemove', this.calcSelectionRect, false)
    this.screenshot.addEventListener('mouseup', this.takeScreenShot, false)
  }

  removeSelectionEventHandlers = () => {
    this.screenshot.removeEventListener('mousemove', this.calcSelectionRect)
    this.screenshot.removeEventListener('mouseup', this.takeScreenShot)
  }

  calcSelectionRect = (e) => {
    if (!this.state.showBorder) {
      this.setState({ showBorder: true })
    }

    let mouseX = e.pageX - window.pageXOffset
    let mouseY = e.pageY - window.pageYOffset
    let w = Math.abs(this.leftOrigin - mouseX)
    let h = Math.abs(this.topOrigin - mouseY)
    let left = this.leftOrigin
    let top = this.topOrigin

    if (mouseX <= this.leftOrigin && mouseY >= this.topOrigin) {
      left = mouseX
    } else if (mouseY <= this.topOrigin && mouseX >= this.leftOrigin) {
      top = mouseY
    } else if (mouseY < this.topOrigin && mouseX < this.leftOrigin) {
      left = mouseX
      top = mouseY
    }

    this.setState({ rect: { width: w, height: h, left, top } })
  }

  // Manipulates clone of DOM, changes queryString for assets for html2canvas to be able to capture
  onclone = (doc) => {
    this.onProcessing()
    Array.from(doc.querySelectorAll('img')).forEach((el) => {
      // Fixes CORS issues that appear when capturing assets
      el.src = `${el.src.slice(
        el.src.indexOf('?') !== -1 ? el.src.indexOf('?') : null
      )}?x-no-cache=${Date.now()}`
      el.crossOrigin = 'anonymous'
    })
  }

  onProcessing = (doc) => {
    this.props.onProcessing(doc)
  }

  takeScreenShot = () => {
    // Remove the selection event handlers and selection element
    const rect = this.state.rect
    this.setState({ rect: { width: 0, height: 0, left: 0, top: 0 } })
    this.removeSelectionEventHandlers()

    let options = {
      ...this.props.html2canvasConfig,
      onclone: (doc) => {
        this.onclone(doc)
        if (typeof this.props.html2canvasConfig.onclone === 'function') {
          this.props.html2canvasConfig.onclone(doc)
        }
      },
    }

    // Must select from document.body as this element contains the elements to be captured.
    // See here: https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
    if (rect.width > this.min && rect.height > this.min) {
      this.setState({ showLoader: true })
      html2canvas(document.body, options).then((canvas) => {
        let screenShotCanvas = document.createElement('canvas')
        const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
        const scrollLeft = document.body.scrollLeft || document.documentElement.scrollLeft
        screenShotCanvas.width = rect.width
        screenShotCanvas.height = rect.height

        let context = screenShotCanvas.getContext('2d')
        context.imageSmoothingEnabled = true

        try {
          context.drawImage(
            canvas,
            rect.left + scrollLeft,
            rect.top + scrollTop,
            rect.width,
            rect.height,
            0,
            0,
            rect.width,
            rect.height
          )
        } catch (e) {
          throw new Error(e)
        }

        if (this.props.saveFile) {
          let link = document.createElement('a')
          link.href = screenShotCanvas.toDataURL('image/jpg') // function blocks CORS
          link.download = 'screenshot.jpg'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }

        if (this.props.onSnapShot) {
          this.setState({ showLoader: false, finished: true })
          this.props.onSnapShot(screenShotCanvas)
        }
      })
    }
  }

  render() {
    const { showBorder, showLoader, finished } = this.state
    return (
      <div
        className={`${styles.screenShot} ${showBorder ? styles.showBorder : ''} ${
          showLoader ? styles.showLoader : ''
        } ${finished ? styles.finished : ''}`}
        ref={(i) => (this.screenshot = i)}
      >
        <div
          ref={(i) => (this.selectionRect = i)}
          className={styles.screenShotSelectionRect}
          style={{ ...this.state.rect }}
        />
      </div>
    )
  }
}

ScreenShot.propTypes = {
  onSnapShot: PropTypes.func.isRequired,
  onProcessing: PropTypes.func.isRequired,
  html2canvasConfig: PropTypes.object.isRequired,
  saveFile: PropTypes.bool,
}

ScreenShot.defaultProps = {
  saveFile: false,
}

export default ScreenShot
